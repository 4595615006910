import { assert, equalsNoCase } from '@microsoft/vscodeedu-api';
import { Root as hastRoot } from 'hast';
import { produce } from 'immer';
import React, { Dispatch, ReactElement, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import * as production from 'react/jsx-runtime';
import rehypeParse from 'rehype-parse';
import rehypeReact, { Options } from 'rehype-react';
import rehypeSanitize from 'rehype-sanitize';
import { createSelector } from 'reselect';
import { unified } from 'unified';
import { visit } from 'unist-util-visit';
import { ContextProps, StoreState, useConfig } from '.';
import { Post } from '../models';
import { getFetchUrl } from '../utilities';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';
import { rehypeReactOptions } from '../utilities/markdown';

// Project templates store.
export type PostStore = Readonly<{
    state: StoreState;
    items: Post[];
}>;

// Project template store action.
export type PostAction = { type: 'Load' } | { type: 'LoadResult'; items: Post[] } | { type: 'LoadError' };

// Project templates context.
export const PostContext = createContext<[state: PostStore, reducer: Dispatch<PostAction>]>(undefined!);

// Project templates context provider.
export const PostContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const { rssFeedUrl } = useConfig();
    const runner = useAsyncRunner();

    // Loads all available posts.
    const loadPosts = useCallback(
        async () =>
            runner.run({
                task: async () => {
                    const items = await loadRssFeed(rssFeedUrl);
                    items.sort((a, b) => b.publishedDate.getTime() - a.publishedDate.getTime());
                    dispatch({ type: 'LoadResult', items });
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading posts.',
                }),
            }),
        [runner, intl, rssFeedUrl]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: PostStore, action: PostAction) =>
            produce(store, (draft) => {
                traceEvent('project-template-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'Load': {
                        if (draft.state === 'NotLoaded') {
                            loadPosts();
                            draft.state = 'Loading';
                        }
                        break;
                    }
                    case 'LoadResult':
                        draft.state = 'Loaded';
                        draft.items = action.items;
                        break;
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [loadPosts]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'NotLoaded', items: [] });
    return <PostContext.Provider value={[state, dispatch]}>{props.children}</PostContext.Provider>;
});

// Use posts context.
export const usePosts = () => {
    const [store, dispatch] = useContext(PostContext);
    useEffect(() => dispatch({ type: 'Load' }), [dispatch]);
    return [store, dispatch] as [typeof store, typeof dispatch];
};

// Selector to return all posts.
export const getPosts = createSelector([(store: PostStore) => store.items], (items) => items);

// Selector to return pinned post, if present.
export const getPinnedPost = createSelector([(store: PostStore) => store.items], (items) =>
    items.find((o) => o.isPinned)
);

async function loadRssFeed(rssFeedUrl: string): Promise<Post[]> {
    const response = await fetch(rssFeedUrl);
    const text = await response.text();
    const parser = new DOMParser();
    const document = parser.parseFromString(text, 'application/xml');
    const result = [] as Post[];
    for (const item of document.querySelectorAll('item')) {
        const rssId = item.querySelector('guid')?.textContent ?? '';
        const id = rssId.split('-')?.pop() ?? '';
        const category = 'improved';
        const publishedDate = new Date(item.querySelector('pubDate')?.textContent ?? '');
        const title = item.querySelector('title')?.textContent ?? '';
        const description = item.querySelector('description')?.textContent ?? '';
        const [content, imageUrl, summary] = await renderContent(description);
        const cardImage = imageUrl ?? getFetchUrl('/post-card.png');
        const isPinned = equalsNoCase(item.querySelector('topicPinned')?.textContent, 'Yes');
        result.push({ rssId, id, category, publishedDate, title, cardImage, content, isPinned, summary });
    }
    return result;
}

async function renderContent(content: string): Promise<[ReactElement, string | undefined, string]> {
    let cardImage: string | undefined = undefined;
    let summary = '';
    const inspectHtml = () => (root: hastRoot) =>
        visit(root, 'element', (node) => {
            const className = node.properties.className as string[];
            let remove = false;
            switch (node.tagName) {
                case 'p':
                    if (node.children.length === 1 && node.children[0]?.type === 'element') {
                        const child = node.children[0];
                        switch (child.tagName) {
                            case 'small':
                                remove = true;
                                break;
                            case 'a':
                                remove = (child.properties.href as string)?.startsWith(
                                    'https://community.vscodeedu.com'
                                );
                                break;
                        }
                    }
                    if (!remove && !summary.length) {
                        summary = node.children.map((o) => (o.type === 'text' ? o.value : '')).join('');
                    }
                    break;
                case 'sup':
                    // Remove footnote references.
                    node.children = [];
                    break;
                case 'hr':
                    // Remove footnotes separator.
                    remove = className?.[0] === 'footnotes-sep';
                    break;
                case 'ol':
                    // Remove footnotes list.
                    remove = className?.[0] === 'footnotes-list';
                    break;
                case 'img':
                    // Record first image URL to use as card image.
                    cardImage ??= node.properties.src as string;
                    break;
                case 'a': {
                    const url = new URL(node.properties.href as string);
                    if (url.hostname !== window.location.hostname) {
                        // Open external links in new tab.
                        node.properties.target = '_blank';
                        node.properties.rel = 'noopener noreferrer';
                    }
                    break;
                }
            }

            if (remove) {
                // Remove elements that are discourse-specific.
                root.children = root.children.filter((n) => n !== node);
            }
        });

    const options = { ...production, components: rehypeReactOptions } as Options;
    const processor = unified()
        .use(rehypeParse, { fragment: true })
        .use(inspectHtml)
        .use(rehypeSanitize)
        .use(rehypeReact, options);

    const out = await processor.process(content);
    return [out.result, cardImage, summary];
}
