import { ButtonProps, DialogTrigger, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { useAppStyles } from '../../styles';
import { TrackedButton } from '../common/tracked-controls';

// Dialog confirm button with event tracking and waiting spinner.
export default (props: ButtonProps) => {
    const { children } = props;
    const styles = useAppStyles();

    return (
        <DialogTrigger action="close">
            <TrackedButton
                {...props}
                trackAction="confirm"
                className={mergeClasses(styles.button, styles.buttonPrimary, styles.buttonMedium, props.className)}
            >
                {children}
            </TrackedButton>
        </DialogTrigger>
    );
};
