import React from 'react';
import { IconProps } from './icon-props';

export const BeginnerIcon = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
        <path
            fill="currentColor"
            d="M 11 3a2 2 0 1 1 4 0v10a2 2 0 1 1-4 0V3Zm2 11a1 1 0 0 0 1-1V3a1 1 0 1 0-2 0v10a1 1 0 0 0 1 1ZM1 9a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0V9ZM8 5a2 2 0 0 0-2 2v6a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2Zm0 1a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1Z"
        />
    </svg>
);

export const IntermediateIcon = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
        <path
            fill="currentColor"
            d="M11 3a2 2 0 1 1 4 0v10a2 2 0 1 1-4 0V3Zm2 11a1 1 0 0 0 1-1V3a1 1 0 1 0-2 0v10a1 1 0 0 0 1 1ZM1 9a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0V9ZM8 5a2 2 0 0 0-2 2v6a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2Z"
        />
    </svg>
);

export const ExpertIcon = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
        <path
            fill="currentColor"
            d="M11 3a2 2 0 1 1 4 0v10a2 2 0 1 1-4 0V3ZM 1 9a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0V9ZM8 5a2 2 0 0 0-2 2v6a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2Z"
        />
    </svg>
);
