import { makeStaticStyles } from '@fluentui/react-components';

export const useMarkdownStyles = makeStaticStyles({
    '.markdown-content h1': {
        fontSize: '32px',
        fontWeight: '600',
        lineHeight: '40px',
        marginTop: '1rem',
        marginBottom: '0.25rem',
    },
    '.markdown-content h2': {
        fontSize: '28px',
        fontWeight: '600',
        lineHeight: '36px',
        marginTop: '1rem',
        marginBottom: '0.25rem',
    },
    '.markdown-content h3': {
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '32px',
        marginTop: '1rem',
        marginBottom: '0.25rem',
    },
    '.markdown-content h4': {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '28px',
        marginTop: '1rem',
        marginBottom: '0.25rem',
    },
    '.markdown-content blockquote': {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '30px',
        paddingLeft: '15px',
        borderLeftWidth: '2px',
        borderLeftStyle: 'solid',
        borderLeftColor: 'rgb(204,204,204)',
    },
    '.markdown-content p': {
        marginTop: '0',
    },
    '.markdown-content a': {
        textDecorationLine: 'underline',
    },
});
