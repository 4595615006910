import React from 'react';
import { useFetched, useUnsafeRenderer } from '../utilities/markdown';

// Unsafe markdown content properties.
export type MarkdownContentProps = Readonly<{
    src?: string;
    markdown?: string;
}>;

// Unsafe markdown content.
export default (props: MarkdownContentProps) => {
    const { src, markdown } = props;
    const fetched = useFetched(src);
    const content = useUnsafeRenderer(fetched ?? markdown);

    return <>{content}</>;
};
