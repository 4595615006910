import React from 'react';
import { UserProgress } from '../../models';
import { useAppStyles } from '../../styles';
import LoadingCard from '../common/loading-card';
import CourseProgressCard from './course-progress-card';
import EmptyState from './empty-state';
import UnenrollDialog from './unenroll-dialog';

// Course progress list properties.
export type CourseProgressListProps = Readonly<{
    isLoading: boolean;
    items: ReadonlyArray<UserProgress>;
    onDownload: (courseId: string) => void;
    onUnenroll: (courseId: string) => void;
}>;

// Course progress list.
export default (props: CourseProgressListProps) => {
    const { isLoading, items, onDownload, onUnenroll } = props;
    const styles = useAppStyles();
    const [unenrollTarget, setUnenrollTarget] = React.useState<UserProgress>();

    return (
        <div className={styles.cardsContainer}>
            {isLoading && <LoadingCard showImage />}

            {!isLoading && items.length === 0 && <EmptyState />}

            {!isLoading &&
                items.length > 0 &&
                items.map((item) => (
                    <CourseProgressCard
                        key={item.courseId}
                        {...item}
                        {...item.course}
                        onDownload={() => onDownload(item.courseId)}
                        onUnenroll={() => setUnenrollTarget(item)}
                    />
                ))}

            <UnenrollDialog
                open={!!unenrollTarget}
                onOpenChange={() => setUnenrollTarget(undefined)}
                courseTitle={unenrollTarget?.course.title ?? ''}
                onDownload={() => onDownload(unenrollTarget!.courseId)}
                onUnenroll={() => onUnenroll(unenrollTarget!.courseId)}
            />
        </div>
    );
};
