import { tokens } from '@fluentui/tokens';
import React from 'react';
import { IconProps } from './icon-props';

export const MicrosoftLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
        <rect x="1.75" y="1.75" width="5.9375" height="5.9375" fill={tokens.colorNeutralForeground1} />
        <rect x="8.3125" y="1.75" width="5.9375" height="5.9375" fill={tokens.colorNeutralForeground1} />
        <rect x="8.3125" y="8.3125" width="5.9375" height="5.9375" fill={tokens.colorNeutralForeground1} />
        <rect x="1.75" y="8.3125" width="5.9375" height="5.9375" fill={tokens.colorNeutralForeground1} />
    </svg>
);

export const GoogleLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" {...props}>
        <path
            fill="currentColor"
            d="M14.9827 6.76074H8.59375V9.44714H12.2651C12.0893 10.2736 11.6225 10.9762 10.9316 11.4568V13.157H13.0741C14.3508 11.9534 15.0854 10.1739 15.0854 8.06995C15.0854 7.61716 15.0488 7.18089 14.9827 6.76074Z"
        />
        <path
            fill="currentColor"
            d="M13.0742 13.1569L12.3295 12.5659L10.9317 11.4568C10.3165 11.8848 9.52421 12.1373 8.59388 12.1373C6.81567 12.1373 5.306 10.9156 4.75971 9.26636H2.55371V11.0168C3.66601 13.2754 5.9522 14.8256 8.59388 14.8256C10.4183 14.8256 11.9546 14.2127 13.0742 13.1569Z"
        />
        <path
            fill="currentColor"
            d="M4.7599 9.2662C4.61849 8.83895 4.54055 8.38366 4.54055 7.91263C4.54055 7.44179 4.61849 6.98651 4.7599 6.55926V4.80859H2.55369C2.09397 5.74221 1.8335 6.79602 1.8335 7.91263C1.8335 9.02943 2.09397 10.0832 2.55369 11.0169L4.7599 9.2662Z"
        />
        <path
            fill="currentColor"
            d="M11.2176 4.73697L13.1173 2.80175C11.9455 1.68514 10.4176 1 8.59392 1C5.95222 1 3.66601 2.5502 2.55371 4.80878L4.75973 6.55927C5.30602 4.90979 6.8157 3.68833 8.59392 3.68833C9.60181 3.68833 10.5049 4.04319 11.2176 4.73697Z"
        />
    </svg>
);

export const CleverLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" {...props}>
        <path
            fill="currentColor"
            d="M14.7532 3.38533L12.7794 5.27984C10.016 2.62753 7.18688 4.17471 6.11775 5.27984C5.13084 6.70071 4.81315 8.52202 6.11775 10.4897C7.84486 13.0947 11.6281 11.7527 13.0262 10.4897L15 12.3842C14.4243 13.1736 12.4834 14.7997 9.32523 14.9892C6.1671 15.1786 3.61169 12.8406 2.87155 11.4198C1.63787 9.28845 1.08444 4.23786 5.62425 1.96446C10.1641 -0.308945 13.6018 1.96446 14.7532 3.38533Z"
        />
    </svg>
);

export const GitHubLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
        <path
            fill="currentColor"
            d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z"
        />
    </svg>
);
