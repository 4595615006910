import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    Field,
    Input,
    Select,
    Textarea,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlProps, useQuery } from './common';
import { MOBILE_AND_BELOW, useAppStyles } from '../styles';
import { anonymousClient } from '../data';
import { WarningImage } from '../icons/status';
import { CloseButton } from './dialogs';
import { useAsyncRunner } from '../utilities/async-runner';
import CancelButton from './dialogs/cancel-button';
import ConfirmButton from './dialogs/confirm-button';
import { AbuseCategory } from '@microsoft/vscodeedu-api';

const useStyles = makeStyles({
    root: {
        minWidth: '42rem',
        [MOBILE_AND_BELOW]: {
            minWidth: 'unset !important',
            maxWidth: '21rem',
        },
    },
    content: {
        rowGap: '.25rem',
    },
});

// User report abuse dialog properties.
export type ReportDialogProps = Readonly<Partial<DialogProps> & IntlProps>;
// Report user content page.
export default injectIntl((props: ReportDialogProps) => {
    const { intl, open } = props;
    const styles = useStyles();
    const appStyles = useAppStyles();
    const query = useQuery();
    const runner = useAsyncRunner();

    const [reportUrl, setReportUrl] = useState(query.get('reportedUrl') ?? '');
    const [abuseCategory, setAbuseCategory] = useState<AbuseCategory>('OffensiveContent');
    const [reason, setReason] = useState('');
    const [requestFollowUp, setRequestFollowUp] = useState(false);
    const [reporterFullName, setReporterFullName] = useState('');
    const [reporterEmail, setReporterEmail] = useState('');

    const onConfirm = useCallback(async () => {
        const trimmedUrl = reportUrl.trim();
        const url = trimmedUrl.startsWith('http') ? trimmedUrl : `https://${trimmedUrl}`;
        runner.run({
            task: async () => {
                await anonymousClient.reportAbuse({
                    body: {
                        url: url,
                        reason: reason,
                        abuseCategory: abuseCategory,
                        reporterEmail: requestFollowUp ? reporterEmail?.trim() : undefined,
                        reporterName: requestFollowUp ? reporterFullName?.trim() : undefined,
                    },
                });
            },
            successMessage: intl.formatMessage({
                description: 'Text indicating that report has been submitted.',
                defaultMessage: 'Report submitted, thank you for helping keep VSCode EDU safe',
            }),
            errorMessage: intl.formatMessage({
                description: 'Error message for an report failing to submit.',
                defaultMessage: 'Unable to submit report, please try again later.',
            }),
        });
    }, [reason, reportUrl, abuseCategory, requestFollowUp, reporterEmail, reporterFullName, intl, runner]);

    useEffect(() => {
        setReason('');
        setReportUrl(query.get('reportedUrl') ?? '');
        setRequestFollowUp(false);
        setReporterEmail('');
        setReporterFullName('');
    }, [open, query]);

    return (
        <Dialog {...props}>
            <DialogSurface className={styles.root}>
                <DialogBody>
                    <DialogTitle action={<CloseButton tabIndex={-1} />}>
                        <FormattedMessage description="Report abuse dialog title." defaultMessage="Report Abuse" />
                    </DialogTitle>
                    <DialogContent className={appStyles.flexRowSmallGap}>
                        <WarningImage className={appStyles.dialogImage} />
                        <div className={mergeClasses(appStyles.pageContent, styles.content)}>
                            <div>
                                <FormattedMessage
                                    description="Report abuse dialog content."
                                    defaultMessage="Did you find or receive a link to VS Code for Education content that is offensive?"
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    description="Report abuse dialog content."
                                    defaultMessage="Use this form to report the content and we will take a look at it."
                                />
                            </div>
                            <Field
                                className={appStyles.dialogField}
                                required
                                label={intl.formatMessage({
                                    description: 'Label for report abuse URL text box.',
                                    defaultMessage: 'What is the web address of the content?',
                                })}
                            >
                                <Input
                                    value={reportUrl}
                                    onChange={(_, { value }) => setReportUrl(value)}
                                    maxLength={1000}
                                    placeholder="https://..."
                                />
                            </Field>

                            <Field
                                className={appStyles.dialogField}
                                required
                                label={
                                    <FormattedMessage
                                        description="Label for the report abuse category dropdown."
                                        defaultMessage="What type of abuse are you reporting?"
                                    />
                                }
                            >
                                <Select onChange={(_, { value }) => setAbuseCategory(value as AbuseCategory)}>
                                    <option value="OffensiveContent">
                                        <FormattedMessage
                                            description="Label for the offensive content abuse category."
                                            defaultMessage="Offensive content"
                                        />
                                    </option>
                                    <option value="Fraud">
                                        <FormattedMessage
                                            description="Label for the phishing and fraud abuse category."
                                            defaultMessage="Phishing or fraud"
                                        />
                                    </option>
                                    <option value="ThreatsCyberbullyingHarassment">
                                        <FormattedMessage
                                            description="Label for the threats, cyberbullying, or harassment abuse category."
                                            defaultMessage="Threats, cyberbullying, harassment"
                                        />
                                    </option>
                                    <option value="ChildEndangermentExploitation">
                                        <FormattedMessage
                                            description="Label for the child endangerment or exploitation abuse category."
                                            defaultMessage="Child endangerment or exploitation"
                                        />
                                    </option>
                                    <option value="VirusSpywareMalware">
                                        <FormattedMessage
                                            description="Label for the virus, spyware, malware abuse category."
                                            defaultMessage="Virus, spyware, malware"
                                        />
                                    </option>
                                    <option value="ContentInfringement">
                                        <FormattedMessage
                                            description="Label for the copyright infringement category."
                                            defaultMessage="Copyright infringement"
                                        />
                                    </option>
                                    <option value="Other">
                                        <FormattedMessage
                                            description="Label for the other abuse category."
                                            defaultMessage="Other "
                                        />
                                    </option>
                                </Select>
                            </Field>
                            <Field
                                className={appStyles.dialogField}
                                label={
                                    <FormattedMessage
                                        description="Label for the report reason text box."
                                        defaultMessage="Why do you find the content offensive?"
                                    />
                                }
                            >
                                <Textarea
                                    value={reason}
                                    onChange={(_, { value }) => setReason(value)}
                                    maxLength={2000}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the report reason text box.',
                                        defaultMessage:
                                            'Please provide as much detail as possible regarding the abuse or offensive content you are reporting. Please do not provide any personal or sensitive information.',
                                    })}
                                />
                            </Field>
                            <Checkbox
                                size="large"
                                checked={requestFollowUp}
                                onChange={(_, { checked }) => setRequestFollowUp(checked === true)}
                                label={
                                    <FormattedMessage
                                        description="Label for checkbox for users to request follow up information."
                                        defaultMessage="If you would like us to keep you posted on the status of your report or if we have follow-up questions, just check this box and share your email address. We will not use your email for any other purpose."
                                    />
                                }
                            />
                            {requestFollowUp && (
                                <>
                                    <Field
                                        className={appStyles.dialogField}
                                        required
                                        label={intl.formatMessage({
                                            description: 'Label for reporter name entry text box text box.',
                                            defaultMessage: 'Name',
                                        })}
                                    >
                                        <Input
                                            value={reporterFullName}
                                            onChange={(_, { value }) => setReporterFullName(value)}
                                            maxLength={1000}
                                            placeholder={intl.formatMessage({
                                                description:
                                                    'Placeholder text inside the reporter name entry text box.',
                                                defaultMessage: 'Enter your full name',
                                            })}
                                        />
                                    </Field>
                                    <Field
                                        className={appStyles.dialogField}
                                        required
                                        label={intl.formatMessage({
                                            description: 'Label for reporter email address entry text box text box.',
                                            defaultMessage: 'Email address',
                                        })}
                                    >
                                        <Input
                                            value={reporterEmail}
                                            onChange={(_, { value }) => setReporterEmail(value)}
                                            maxLength={1000}
                                            placeholder={intl.formatMessage({
                                                description:
                                                    'Placeholder text inside the reporter email address entry text box.',
                                                defaultMessage: 'Enter your email address',
                                            })}
                                        />
                                    </Field>
                                </>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton />
                        <ConfirmButton
                            onClick={onConfirm}
                            disabled={!reportUrl || (requestFollowUp && !(reporterEmail && reporterFullName))}
                        >
                            <FormattedMessage
                                description="Report offensive content button submit button text."
                                defaultMessage="Submit"
                            />
                        </ConfirmButton>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});
