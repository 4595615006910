import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    TableColumnDefinition,
    TableColumnSizingOptions,
    createTableColumn,
} from '@fluentui/react-components';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Project, PublishedProject } from '../../models';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import ActionsCell from './actions-cell';
import AutoPublishCell from './auto-publish-cell';
import EmptyState from './empty-state';
import LastPublishedCell from './last-published-cell';
import NameCell from './name-cell';
import UnpublishProjectDialog from './unpublish-project-dialog';
import { compareBooleans, compareDates, compareLocale } from '@microsoft/vscodeedu-api';

const columnSizingOptions: TableColumnSizingOptions = {
    name: {
        idealWidth: 500,
        defaultWidth: 500,
    },
    lastPublished: {
        idealWidth: 300,
        defaultWidth: 300,
    },
    autoPublish: {
        idealWidth: 120,
        defaultWidth: 120,
    },
    actions: {
        idealWidth: 130,
        defaultWidth: 130,
        minWidth: 130,
    },
};

// Published projects list properties.
export type PublishedProjectListProps = Readonly<{
    projects: ReadonlyArray<PublishedProject>;
    onUpdate: (project: Project) => void;
    onPublish: (projectId: string) => void;
    onUnpublish: (projectId: string) => void;
    onShare: (projectId: string) => void;
}>;

// Published projects data grid.
export default (props: PublishedProjectListProps) => {
    const { projects, onUpdate, onPublish, onUnpublish, onShare } = props;
    const appStyles = useAppStyles();
    const [unpublishTarget, setUnpublishTarget] = useState<PublishedProject>();

    const columns = useMemo<TableColumnDefinition<PublishedProject>[]>(
        () => [
            createTableColumn({
                columnId: 'name',
                compare: (a, b) => compareLocale(a.title, b.title),
                renderHeaderCell: () => (
                    <FormattedMessage description="Column title for project name." defaultMessage="Project name" />
                ),
                renderCell: (item) => <NameCell {...item} />,
            }),
            createTableColumn({
                columnId: 'lastPublished',
                compare: (a, b) => compareDates(a.lastPublished, b.lastPublished),
                renderHeaderCell: () => (
                    <FormattedMessage
                        description="Column title for last published time."
                        defaultMessage="Last published"
                    />
                ),
                renderCell: (item) => <LastPublishedCell {...item} />,
            }),
            createTableColumn({
                columnId: 'autoPublish',
                compare: (a, b) => compareBooleans(a.publishOnUpdate, b.publishOnUpdate),
                renderHeaderCell: () => (
                    <FormattedMessage
                        description="Column title for whether to auto-publish."
                        defaultMessage="Auto-publish"
                    />
                ),
                renderCell: (item) => (
                    <AutoPublishCell {...item} onChange={(value) => onUpdate({ ...item, publishOnUpdate: value })} />
                ),
            }),
            createTableColumn({
                columnId: 'actions',
                renderHeaderCell: () => (
                    <FormattedMessage description="Column title for actions." defaultMessage="Actions" />
                ),
                renderCell: (item) => (
                    <ActionsCell
                        {...item}
                        onPublish={() => onPublish(item.projectId)}
                        onUnpublish={() => setUnpublishTarget(item)}
                        onShare={() => onShare(item.projectId)}
                    />
                ),
            }),
        ],
        [onUpdate, onPublish, onShare]
    );

    return (
        <TrackContext.Provider value={{ container: 'published-list' }}>
            {projects.length === 0 && <EmptyState />}
            {projects.length > 0 && (
                <DataGrid
                    items={projects as PublishedProject[]}
                    getRowId={(item) => item.projectId}
                    columns={columns}
                    columnSizingOptions={columnSizingOptions}
                    resizableColumns
                    sortable
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody<Project>>
                        {({ item, rowId }) => (
                            <DataGridRow<Project> key={rowId}>
                                {({ renderCell }) => (
                                    <DataGridCell className={appStyles.flexColumn}>
                                        <TrackContext.Provider
                                            value={{
                                                container: 'published-list-row',
                                                props: { projectId: item.projectId },
                                            }}
                                        >
                                            {renderCell(item)}
                                        </TrackContext.Provider>
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            )}

            <UnpublishProjectDialog
                open={!!unpublishTarget}
                onOpenChange={() => setUnpublishTarget(undefined)}
                title={unpublishTarget?.title ?? ''}
                onUnpublish={() => onUnpublish(unpublishTarget!.projectId)}
            />
        </TrackContext.Provider>
    );
};
