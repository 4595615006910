import { Link, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { PostCategory } from '../../models';
import { useAppStyles } from '../../styles';

// Post card properties.
export type PostCardProps = Readonly<{
    className?: string;
    category: PostCategory;
    title: string;
    cardImage: string;
    publishedDate: Date;
    onClick: () => void;
}>;

// Post card properties.
export default (props: PostCardProps) => {
    const { className, title, cardImage, publishedDate, onClick } = props;
    const styles = useAppStyles();
    const timestamp = publishedDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <div className={mergeClasses(className, styles.card)}>
            <div className={styles.cardMedia}>
                <div className={styles.cardMediaImage} style={{ backgroundImage: `url('${cardImage}')` }} />
            </div>
            <div className={styles.cardContent}>
                <Link className={mergeClasses(styles.cardTitle, styles.linkButton)} onClick={onClick}>
                    {title}
                </Link>
                <div className={styles.cardFooter}>{timestamp}</div>
            </div>
        </div>
    );
};
