import { TableCellLayout } from '@fluentui/react-components';
import React from 'react';
import { injectIntl } from 'react-intl';
import { PublishedProject } from '../../models';
import { IntlProps, TrackedSwitch } from '../common';

// Auto-publish cell properties.
export type AutoPublishCellProps = IntlProps<{
    state: PublishedProject['state'];
    publishOnUpdate?: boolean;
    onChange: (value: boolean) => void;
}>;

// Auto-publish data grid cell contents.
export default injectIntl((props: AutoPublishCellProps) => {
    const { intl, state, publishOnUpdate, onChange } = props;
    return (
        <TableCellLayout>
            <TrackedSwitch
                trackAction="auto-publish"
                disabled={state !== 'Created'}
                checked={publishOnUpdate === true}
                onChange={(_, { checked }) => onChange(checked)}
                title={intl.formatMessage({
                    description: 'Description for auto-publish toggle',
                    defaultMessage: 'Specify whether to automatically publish when project code is updated.',
                })}
            />
        </TableCellLayout>
    );
});
