import { Text, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { LinkedInLogoIcon } from '@fluentui/react-icons-mdl2-branded';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useTheme } from '../data/theme-context';
import { YouTubeLogo } from '../icons/social';
import { KnownLink } from '../models';
import { MOBILE_AND_BELOW, TABLET_AND_BELOW, useAppStyles } from '../styles';
import { getFetchUrl } from '../utilities';
import { IntlProps, TrackContext, TrackedButton, TrackedLink, TrackedNavLink } from './common';

const useStyles = makeStyles({
    container: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground2,
        marginTop: tokens.spacingVerticalXXXL,
        [TABLET_AND_BELOW]: {
            marginTop: 'unset',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: tokens.spacingVerticalXXXL,
        paddingBottom: tokens.spacingVerticalL,
        fontSize: tokens.fontSizeBase200,
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '50px',
        paddingBottom: tokens.spacingVerticalXL,
        [MOBILE_AND_BELOW]: {
            columnGap: tokens.spacingHorizontalS,
            marginLeft: tokens.spacingHorizontalS,
            marginRight: tokens.spacingHorizontalS,
        },
    },
    linkList: {
        display: 'flex',
        flexDirection: 'column',
    },
    linkListHeader: {
        ...shorthands.margin(0),
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightSemibold,
    },
    linkListList: {
        ...shorthands.margin(0),
        ...shorthands.padding(0),
        listStyleType: 'none',
        listStylePosition: 'outside',
        '& li': {
            marginTop: tokens.spacingVerticalS,
        },
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
        justifyContent: 'end',
        paddingBottom: tokens.spacingVerticalXS,
        [MOBILE_AND_BELOW]: {
            columnGap: tokens.spacingHorizontalS,
            marginLeft: tokens.spacingHorizontalS,
            marginRight: tokens.spacingHorizontalS,
        },
    },
    row3: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalXXL,
        alignItems: 'center',
        justifyContent: 'start',
        [MOBILE_AND_BELOW]: {
            columnGap: tokens.spacingHorizontalS,
            marginLeft: tokens.spacingHorizontalS,
            marginRight: tokens.spacingHorizontalS,
        },
    },
    microsoftLogo: {
        height: '20px',
        width: '94px',
    },
    microsoftLogoButton: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    subtleNavButton: {
        fontSize: 'inherit',
    },
});

const currentYear = new Date().getFullYear();

export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useStyles();
    const appStyles = useAppStyles();
    const [{ isDarkTheme }] = useTheme();
    const logoLink = isDarkTheme ? 'microsoft-logo.png' : 'microsoft-logo-inverted.png';

    return (
        <TrackContext.Provider value={{ container: 'footer' }}>
            <div role="contentinfo" className={styles.container}>
                <div className={mergeClasses(styles.content, 'content-width')}>
                    <div className={styles.row1}>
                        <div className={styles.linkList}>
                            <Text as="h2" className={styles.linkListHeader} id="products-header">
                                <FormattedMessage
                                    description="Footer header for products section"
                                    defaultMessage="Products"
                                />
                            </Text>
                            <ul className={styles.linkListList} aria-labelledby="products-header">
                                <li>
                                    <TrackedLink
                                        trackAction="makecode"
                                        appearance="subtle"
                                        href={KnownLink.makeCode}
                                        target="_blank"
                                    >
                                        MakeCode
                                    </TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink
                                        trackAction="vscode"
                                        appearance="subtle"
                                        href={KnownLink.vscode}
                                        target="_blank"
                                    >
                                        Visual Studio Code
                                    </TrackedLink>
                                </li>
                                <li>
                                    <TrackedNavLink trackAction="vscodeedu" className={appStyles.navLink} to="/">
                                        Visual Studio Code for Education
                                    </TrackedNavLink>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.linkList}>
                            <Text as="h2" className={styles.linkListHeader} id="support-header">
                                <FormattedMessage
                                    description="Footer header for support section"
                                    defaultMessage="Support"
                                />
                            </Text>
                            <ul className={styles.linkListList} aria-labelledby="support-header">
                                <li>
                                    <TrackedLink
                                        trackAction="a11y"
                                        appearance="subtle"
                                        href={KnownLink.accessibility}
                                        target="_blank"
                                    >
                                        <FormattedMessage
                                            description="Footer link for accessibility page"
                                            defaultMessage="Accessibility"
                                        />
                                    </TrackedLink>
                                </li>
                                <li>
                                    <TrackedNavLink trackAction="domains" className={appStyles.navLink} to="/domains">
                                        <FormattedMessage
                                            description="Footer link for domains we use"
                                            defaultMessage="Domains & Connectivity"
                                        />
                                    </TrackedNavLink>
                                </li>
                                <li>
                                    <TrackedNavLink trackAction="notice" className={appStyles.navLink} to="/notice">
                                        <FormattedMessage
                                            description="Footer link for third-party notices"
                                            defaultMessage="Third-party Notices"
                                        />
                                    </TrackedNavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.row2}>
                        <TrackedLink trackAction="careers" appearance="subtle" href={KnownLink.careers} target="_blank">
                            <FormattedMessage description="Footer link for jobs page" defaultMessage="Jobs" />
                        </TrackedLink>
                        <TrackedLink
                            trackAction="privacy"
                            appearance="subtle"
                            href={KnownLink.privacyPolicy}
                            target="_blank"
                        >
                            <FormattedMessage
                                description="Footer link for privacy statement"
                                defaultMessage="Privacy"
                            />
                        </TrackedLink>
                        <TrackedLink
                            trackAction="terms"
                            appearance="subtle"
                            href={KnownLink.termsOfUse}
                            target="_blank"
                        >
                            <FormattedMessage
                                description="Footer link for terms of use"
                                defaultMessage="Terms of Use"
                            />
                        </TrackedLink>
                        <TrackedLink
                            trackAction="trademarks"
                            appearance="subtle"
                            href={KnownLink.trademarks}
                            target="_blank"
                        >
                            <FormattedMessage
                                description="Footer link for trademarks page"
                                defaultMessage="Trademarks"
                            />
                        </TrackedLink>
                        <span>© Microsoft {currentYear}</span>
                    </div>
                    <div className={styles.row3}>
                        <TrackedButton
                            trackAction="linkedin"
                            as="a"
                            appearance="subtle"
                            title={intl.formatMessage({
                                description: 'title text for LinkedIn logo in the footer',
                                defaultMessage: 'Follow us on LinkedIn',
                            })}
                            icon={<LinkedInLogoIcon />}
                            href={KnownLink.vsceLinkedIn}
                            target="_blank"
                        />
                        <TrackedButton
                            trackAction="youtube"
                            as="a"
                            appearance="subtle"
                            title={intl.formatMessage({
                                description: 'title text for YouTube logo in the footer',
                                defaultMessage: 'Follow us on YouTube',
                            })}
                            icon={<YouTubeLogo />}
                            href={KnownLink.vsceYouTube}
                            target="_blank"
                        />
                        <div className={appStyles.flexGrow1} />
                        <TrackedButton
                            trackAction="ms-com"
                            className={styles.microsoftLogoButton}
                            as="a"
                            appearance="subtle"
                            href={KnownLink.microsoftHome}
                            target="_blank"
                        >
                            <img
                                className={styles.microsoftLogo}
                                src={getFetchUrl(logoLink)}
                                alt={intl.formatMessage({
                                    description: 'alt text for Microsoft logo in the footer',
                                    defaultMessage: 'Microsoft Homepage',
                                })}
                            />
                        </TrackedButton>
                    </div>
                </div>
            </div>
        </TrackContext.Provider>
    );
});
