import { Spinner, Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components';
import React, { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackError } from './diagnostics';
import { isNonEmptyString } from '@microsoft/vscodeedu-api';

// Properties for asynchronous runner.
export type AsyncRunnerProps = Readonly<{
    task: () => Promise<void>;
    onError?: (error: unknown) => void | string | Promise<void | string>;
    progressMessage?: string | ReactElement;
    successMessage?: string | ReactElement;
    errorMessage?: string | ReactElement;
    errorDetails?: string | ReactElement;
}>;

// Asynchronous operation runner with toast UI notifications.
export const useAsyncRunner = () => {
    const { dispatchToast, dismissToast } = useToastController('app-toaster');
    return useMemo(
        () => ({
            run: async (props: AsyncRunnerProps) => {
                const { task, onError, progressMessage, successMessage, errorMessage, errorDetails } = props;
                try {
                    if (isNonEmptyString(progressMessage)) {
                        dispatchToast(
                            <Toast appearance="inverted">
                                <ToastTitle media={<Spinner size="tiny" />}>{progressMessage}</ToastTitle>
                                <ToastBody
                                    subtitle={
                                        <FormattedMessage
                                            description="Text on progress toast."
                                            defaultMessage="Please wait..."
                                        />
                                    }
                                ></ToastBody>
                            </Toast>,
                            { intent: 'info', toastId: 'progress' }
                        );
                    }

                    await task();

                    dismissToast('progress');
                    if (isNonEmptyString(successMessage)) {
                        dispatchToast(
                            <Toast appearance="inverted">
                                <ToastTitle>{successMessage}</ToastTitle>
                            </Toast>,
                            { intent: 'success' }
                        );
                    }
                } catch (error) {
                    trackError(error);

                    let updatedErrorMessage = errorMessage;
                    if (onError) {
                        updatedErrorMessage = (await onError(error)) ?? errorMessage;
                    }

                    dismissToast('progress');
                    if (isNonEmptyString(updatedErrorMessage)) {
                        dispatchToast(
                            <Toast appearance="inverted">
                                <ToastTitle>{updatedErrorMessage}</ToastTitle>
                                {errorDetails !== undefined && <ToastBody subtitle={errorDetails}></ToastBody>}
                            </Toast>,
                            { intent: 'error' }
                        );
                    }
                }
            },
        }),
        [dispatchToast, dismissToast]
    );
};
