import React, { useState } from 'react';
import { Project } from '../../models';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import LoadingCard from '../common/loading-card';
import DeleteProjectDialog from './delete-project-dialog';
import EmptyState from './empty-state';
import ProjectCard from './project-card';
import UpdateProjectDialog from './update-project-dialog';

// Project card list properties.
export type ProjectListProps = Readonly<{
    projects: ReadonlyArray<Project>;
    isLoading: boolean;
    onCreate: () => void;
    onPublish: (projectId: string) => void;
    onShowPublished: () => void;
    onUpdate: (project: Project) => void;
    onDelete: (projectId: string) => void;
    onDownload: (projectId: string) => void;
    onShare: (projectId: string) => void;
}>;

// Project card list.
export default (props: ProjectListProps) => {
    const { projects, isLoading, onCreate, onPublish, onShowPublished, onUpdate, onDelete, onDownload } = props;
    const styles = useAppStyles();
    const [deleteTarget, setDeleteTarget] = useState<Project>();
    const [updateTarget, setUpdateTarget] = useState<Project>();

    return (
        <TrackContext.Provider value={{ container: 'project-list' }}>
            {!isLoading && !projects.length && <EmptyState onCreate={onCreate} />}
            {(isLoading || !!projects.length) && (
                <div className={styles.cardsContainer}>
                    {isLoading && <LoadingCard showImage />}
                    {!isLoading &&
                        projects.map((item) => (
                            <ProjectCard
                                key={item.projectId}
                                project={item}
                                onPublish={() => onPublish(item.projectId)}
                                onShowPublished={onShowPublished}
                                onDelete={() => setDeleteTarget(item)}
                                onUpdate={() => setUpdateTarget(item)}
                                onDownload={() => onDownload(item.projectId)}
                                onShare={() => props.onShare(item.projectId)}
                            />
                        ))}
                </div>
            )}

            <DeleteProjectDialog
                open={!!deleteTarget}
                onOpenChange={() => setDeleteTarget(undefined)}
                projectTitle={deleteTarget?.title ?? ''}
                onDelete={() => onDelete(deleteTarget!.projectId)}
                onDownload={() => onDownload(deleteTarget!.projectId)}
            />

            <UpdateProjectDialog
                open={!!updateTarget}
                onOpenChange={() => setUpdateTarget(undefined)}
                initialTitle={updateTarget?.title ?? ''}
                initialDescription={updateTarget?.description}
                onUpdate={(title, description) => onUpdate({ ...updateTarget!, title, description })}
            />
        </TrackContext.Provider>
    );
};
