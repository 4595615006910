import { ApiEnvironment, VsCodeEduClientExt, assert, startsWithNoCase } from '@microsoft/vscodeedu-api';
import { produce } from 'immer';
import React, { Dispatch, PropsWithChildren, createContext, useCallback, useContext, useReducer } from 'react';
import { createSelector } from 'reselect';
import { StoreState } from '.';
import { Config } from '../models';
import { traceEvent } from '../utilities';

// Current API environment.
export const apiEnvironment = (function () {
    const { hostname, pathname } = window.location;
    switch (hostname.toLocaleLowerCase()) {
        case 'vscodeedu.com':
            return startsWithNoCase(pathname, '/beta') ? ApiEnvironment.Beta : ApiEnvironment.Production;
        case 'ppe.vscodeedu.com':
            return ApiEnvironment.PreProduction;
        case 'localhost':
            return process.env.REACT_APP_VERSION === 'localhost'
                ? ApiEnvironment.LocalHost
                : ApiEnvironment.PreProduction;
        default:
            throw Error(`Unexpected hostname value: ${hostname}.`);
    }
})();

// Returns whether current environment is Production.
export const isProduction = apiEnvironment === ApiEnvironment.Production;

// Returns whether current environment is Beta.
export const isBeta = apiEnvironment === ApiEnvironment.Beta;

// Router base name based on the current environment.
export const routerBasename = (function () {
    if (isBeta) {
        return '/beta';
    } else {
        const publicUrl = process.env.PUBLIC_URL;
        return startsWithNoCase(window.location.pathname, publicUrl) ? publicUrl : '';
    }
})();

// Anonymous VS Code Edu API client.
export const anonymousClient = (function () {
    const client = new VsCodeEduClientExt(apiEnvironment);
    client.pipeline.addPolicy({
        name: 'authorization',
        sendRequest: async (request, next) => {
            if (request.method === 'GET') {
                // Remove headers triggering pre-flight requests.
                request.headers.delete('x-ms-client-request-id');
                request.headers.delete('x-ms-useragent');
            }
            return await next(request);
        },
    });
    return client;
})();

// Config store.
export type ConfigStore = Readonly<{
    config: Config;
    state: StoreState;
}>;

// Config store action.
export type ConfigAction = { type: 'Update'; config: Partial<Config> } | { type: 'Reset' };

// Config context.
export const ConfigContext = createContext<[state: ConfigStore, reducer: Dispatch<ConfigAction>]>(undefined!);

// Config context provider.
export const ConfigContextProvider = (props: PropsWithChildren) => {
    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: ConfigStore, action: ConfigAction) =>
            produce(store, (draft) => {
                traceEvent('config-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'Update':
                        draft.config = { ...draft.config, ...action.config };
                        break;
                    case 'Reset':
                        draft.config = staticConfig!;
                        break;
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        []
    );

    const [state, dispatch] = useReducer(reducer, { state: 'Loaded', config: staticConfig! });
    return <ConfigContext.Provider value={[state, dispatch]}>{props.children}</ConfigContext.Provider>;
};

// Use config context.
export const useConfig = () => {
    const [{ config }] = useContext(ConfigContext);
    return config;
};

// Selector to get current config.
export const getConfig = createSelector([(store: ConfigStore) => store], (store) => store.config);

// Loads config from the API.
async function loadConfigFromApi(): Promise<Partial<Config>> {
    const { settings, featureFlags } = await anonymousClient.getConfigSettingsAndFeatureFlags();
    return { ...settings, ...featureFlags };
}

// Loads config from query string.
function loadConfigFromQueryString(): Partial<Config> {
    const result: { [name: string]: boolean } = {};
    const params = new URLSearchParams(window.location.search);
    const featureFlagPrefix = 'feature.';
    for (const name of params.keys()) {
        if (startsWithNoCase(name, featureFlagPrefix)) {
            const value = /^(1|true|on|yes|enable|enabled)$/i.test(params.get(name) ?? '');
            const featureName = name.substring(featureFlagPrefix.length);
            result[featureName] = value;
        }
    }
    return result;
}

let staticConfig: Config | undefined;

// Preloads config so that it is available as soon as possible.
export async function preloadConfig(testConfig?: Partial<Config>) {
    if (!staticConfig) {
        staticConfig =
            (testConfig as Config) ??
            ({
                visibleCourses: '',
                visibleProjectTemplates: '',
                ...(await loadConfigFromApi()),
                ...loadConfigFromQueryString(),
            } as unknown as Config);
    }
}
