import { makeStyles } from '@fluentui/react-components';
import { buttonStyles } from './buttons';
import { cardStyles } from './cards';
import { contentStyles } from './content';
import { dialogStyles } from './dialogs';
import { layoutStyles } from './layout';
import { responsiveStyles } from './responsive';

export * from './responsive';
export * from './theme';
export * from './markdown';

// Shared application styles.
export const useAppStyles = makeStyles({
    ...buttonStyles,
    ...cardStyles,
    ...contentStyles,
    ...dialogStyles,
    ...layoutStyles,
    ...responsiveStyles,
});
