import { tokens } from '@fluentui/react-components';
import { defineStyles } from './define-styles';

// Layout styles and helpers.
export const layoutStyles = defineStyles({
    // Prevents element from growing.
    flexGrow0: {
        flexGrow: 0,
    },
    // Makes element take up all available space.
    flexGrow1: {
        flexGrow: 1,
    },
    // Makes element take equal space.
    flexEqual: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
    // Column layout with items aligned at start.
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    // Column layout with small gap between columns.
    flexColumnSmallGap: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
    },
    // Column layout with large gap between columns.
    flexColumnLargeGap: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalL,
    },
    // Row layout.
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    // Row layout with small gap between rows.
    flexRowSmallGap: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalS,
    },
    // Row layout with small gap between rows, items centered.
    flexRowSmallGapCentered: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalS,
        alignItems: 'center',
    },
    // Row layout with items aligned at center, overflow hidden.
    flexRowCentered: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        overflowX: 'hidden',
    },
    // Center alignments.
    alignCenter: {
        alignItems: 'center',
        textAlign: 'center',
    },
    // XL width and height.
    sizeXL: {
        width: tokens.spacingHorizontalXL,
        height: tokens.spacingHorizontalXL,
    },
});
