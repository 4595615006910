import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { createContext } from 'react';
import { traceEvent } from '../../utilities/diagnostics';

// Event tracking context value.
export type TrackContextValue = Readonly<{
    // Name of container (will become event name prefix).
    container: string;

    // Name of action (will become event name suffix).
    action?: string;

    // Additional custom properties for the action.
    // Will be prepended to those specified on the action.
    props?: Readonly<ICustomProperties>;
}>;

// Properties to track an action invoked by a UI element.
export type TrackedActionProps = Readonly<{
    // Name of action (will become event name suffix).
    trackAction: string;

    // Additional custom properties for the action.
    // Will be appended to those specified on the context.
    trackProps?: Readonly<ICustomProperties>;
}>;

// Root event tracking context.
export const TrackContext = createContext<TrackContextValue>({ container: 'app' });

// Fires telemetry event corresponding to the given context and action properties.
export function trackContext(context: TrackContextValue, actionProps: TrackedActionProps) {
    // Construct event name as 'container.action'.
    const container = context.container;
    const action = actionProps.trackAction ?? context.action;
    const eventName = container + (action ? '.' + action : '');

    // Collect custom properties from container and action.
    const eventProps = { ...context.props, ...actionProps.trackProps };

    traceEvent(eventName, eventProps);
}
