import { Theme, webDarkTheme, webLightTheme } from '@fluentui/react-components';

// Default app theme.
export const darkTheme: Theme = {
    ...webDarkTheme,

    colorNeutralForeground1: '#F8F8F8', // primary strong white (titles etc)
    colorNeutralForeground2: '#CCCCCC', // secondary off-white (regular text)

    colorNeutralBackground1: '#1E1E1E', // overall site background
    colorNeutralBackground2: '#252526', // background for cards and dialogs
    colorNeutralBackground3: '#3C3C3C', // progress bar

    colorBrandStroke1: '#115ea3', // primary button outline
    colorNeutralStroke2: '#1E1E1E', // outline of header icons

    colorNeutralBackgroundStatic: '#000000',
    colorBrandBackgroundHover: '#0065A9',
    colorBrandBackground2: '#6068DD',
    colorPaletteBlueForeground2: '#4F95F7', // progress bar
    colorPaletteYellowBackground1: '#FFC541',
};

export const lightTheme: Theme = {
    ...webLightTheme,

    colorNeutralForeground1: '#1E1E1E',
    colorNeutralForeground2: '#252526',

    colorNeutralBackground1: '#FFFFFF',
    colorNeutralBackground2: '#FAFAFA',
    colorNeutralBackground3: '#F5F5F5',

    colorStatusDangerBackground2: '#c50f1f', // Delete account button enabled
    colorStatusDangerBackground3: '#a80000', // Delete account button hover

    colorNeutralStroke1: '#666666', // Outline of secondary buttons
    colorNeutralStroke2: '#FFFFFF', // outline of header icons

    colorNeutralBackgroundStatic: '#FFFFFF',
    colorBrandBackgroundHover: '#0065A9',
    colorNeutralBackground1Hover: '#F5F5F5',
    colorBrandStroke1: '#0f6cbd',
    colorBrandBackground2: '#6068DD',
    colorPaletteBlueForeground2: '#4F95F7',
    colorPaletteYellowBackground1: '#FFC541',
};

export const highContrastLightTheme: Theme = {
    ...lightTheme,

    colorNeutralBackground1: '#FFFFFF',
    colorNeutralBackground2: '#FFFFFF',
    colorNeutralBackground3: '#FFFFFF',

    colorNeutralForeground1: '#000000',
    colorNeutralForeground2: '#000000',
    colorNeutralForeground3: '#000000',

    colorBrandBackground: '#0F4C82', // Primary button
    colorBrandStroke1: '#0F4C82', // Primary button outline

    colorNeutralStroke1: '#0f4a85', // Outline of secondary buttons
    colorNeutralStroke2: '#0f4a85', // Outline of header icons
    colorNeutralStroke3: '#0f4a85', // Outline of cards

    colorStrokeFocus2: '#0f4a85', // tab-selected items
};

export const highContrastDarkTheme: Theme = {
    ...darkTheme,

    colorNeutralBackground1: '#000000',
    colorNeutralBackground2: '#000000',
    colorNeutralBackground3: '#000000',

    colorBrandBackground: '#000000', // Primary button
    colorBrandForegroundOnLightHover: '#000000', // Primary button hover color
    colorBrandStroke1: '#82C2DC', // Primary button outline
    colorBrandBackgroundHover: '#82C2DC', // Primary button outline hover color

    colorNeutralBackground1Hover: '#000000', // Secondary button hover color
    colorNeutralStroke1Hover: '#82C2DC', // Secondary button outline hover color

    colorNeutralForeground1: '#FFFFFF',
    colorNeutralForeground2: '#FFFFFF',
    colorNeutralForeground3: '#FFFFFF',

    colorNeutralStroke1: '#82C2DC', // Outline of secondary buttons
    colorNeutralStroke2: '#82C2DC', // Outline of header icons
    colorNeutralStroke3: '#82C2DC', // Outline of cards

    colorStrokeFocus2: '#f38518', // Tab-slected items
};
