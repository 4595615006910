import { Accordion } from '@fluentui/react-components';
import { Unit } from '@microsoft/vscodeedu-api';
import React from 'react';
import UnitSection from './unit-section';

// Course table of contents properties.
export type TableOfContentsProps = Readonly<{
    units?: Unit[];
    openCourseUrl: string;
}>;

// Course table of contents.
export default (props: TableOfContentsProps) => {
    const { units, openCourseUrl } = props;

    return (
        <Accordion collapsible multiple>
            {units?.map((unit) => <UnitSection key={unit.id} unit={unit} openCourseUrl={openCourseUrl} />)}
        </Accordion>
    );
};
