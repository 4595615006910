import {
    Button,
    ButtonProps,
    Link,
    LinkProps,
    MenuItem,
    MenuItemProps,
    MenuItemRadio,
    MenuItemRadioProps,
    Switch,
    SwitchProps,
} from '@fluentui/react-components';
import React, { MouseEvent } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { TrackContext, TrackedActionProps, trackContext } from './track-context';

// Button tracking click actions via events.
export const TrackedButton = (props: Readonly<ButtonProps & TrackedActionProps>) => (
    <TrackContext.Consumer>
        {(context) => (
            <Button
                {...props}
                onClick={(event: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>) => {
                    const { onClick, trackAction, trackProps } = props;
                    trackContext(context, { trackAction, trackProps });
                    onClick?.(event);
                }}
            />
        )}
    </TrackContext.Consumer>
);

// Link tracking click actions via events.
export const TrackedLink = (props: Readonly<LinkProps & TrackedActionProps>) => (
    <TrackContext.Consumer>
        {(context) => (
            <Link
                {...props}
                onClick={(event: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>) => {
                    const { onClick, trackAction, trackProps } = props;
                    trackContext(context, { trackAction, trackProps });
                    onClick?.(event);
                }}
            />
        )}
    </TrackContext.Consumer>
);

// NavLink tracking click actions via events.
export const TrackedNavLink = (props: Readonly<NavLinkProps & TrackedActionProps>) => (
    <TrackContext.Consumer>
        {(context) => (
            <NavLink
                {...(Object.fromEntries(
                    Object.entries(props).filter((o) => o[0] !== 'trackAction' && o[0] !== 'trackProps')
                ) as NavLinkProps)}
                onClick={(event: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>) => {
                    const { onClick, trackAction, trackProps } = props;
                    trackContext(context, { trackAction, trackProps });
                    onClick?.(event);
                }}
            />
        )}
    </TrackContext.Consumer>
);

// MenuItem tracking click actions via events.
export const TrackedMenuItem = (props: Readonly<MenuItemProps & TrackedActionProps>) => (
    <TrackContext.Consumer>
        {(context) => (
            <MenuItem
                {...props}
                onClick={(event) => {
                    const { onClick, trackAction, trackProps } = props;
                    trackContext(context, { trackAction, trackProps });
                    onClick?.(event);
                }}
            />
        )}
    </TrackContext.Consumer>
);

// MenuItem tracking click actions via events.
export const TrackedMenuItemRadio = (props: Readonly<MenuItemRadioProps & TrackedActionProps>) => (
    <TrackContext.Consumer>
        {(context) => (
            <MenuItemRadio
                {...props}
                onClick={(event) => {
                    const { onClick, trackAction, trackProps } = props;
                    trackContext(context, { trackAction, trackProps });
                    onClick?.(event);
                }}
            />
        )}
    </TrackContext.Consumer>
);

// Switch tracking state changes via events.
export const TrackedSwitch = (props: Readonly<SwitchProps & TrackedActionProps>) => (
    <TrackContext.Consumer>
        {(context) => (
            <Switch
                {...props}
                onChange={(event, data) => {
                    const { onChange, trackAction, trackProps } = props;
                    trackContext(context, {
                        trackAction,
                        trackProps: { ...trackProps, checked: data.checked },
                    });
                    onChange?.(event, data);
                }}
            />
        )}
    </TrackContext.Consumer>
);
