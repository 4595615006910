import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Input,
} from '@fluentui/react-components';
import { ShareRegular, ShareScreenStopRegular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getProject, useConfig, useProjects } from '../../data';
import { useAppStyles } from '../../styles';
import { getSharedLinkUrl } from '../../utilities';
import { IntlProps, TrackContext, TrackedButton } from '../common';
import CopyToClipboardButton from '../common/copy-to-clipboard-button';
import { CloseButton } from '../dialogs';
import StopSharingDialog from './stop-sharing-dialog';

// Share project dialog properties.
export type ShareProjectDialogProps = IntlProps<
    Partial<DialogProps> & {
        projectId: string;
    }
>;

// Share project dialog.
export default injectIntl((props: ShareProjectDialogProps) => {
    const { intl, projectId } = props;
    const styles = useAppStyles();
    const { sharedLinkBaseUrl } = useConfig();
    const [store, dispatch] = useProjects();
    const { state, title, publicUrl, sharedLinkId } = getProject(store, projectId)!;
    const [stopSharingOpen, setStopSharingOpen] = React.useState(false);

    const sharedLinkUrl = useMemo(
        () => (sharedLinkId !== undefined ? getSharedLinkUrl(sharedLinkId, sharedLinkBaseUrl) : ''),
        [sharedLinkId, sharedLinkBaseUrl]
    );

    return (
        <TrackContext.Provider value={{ container: 'share-project-dialog' }}>
            <Dialog {...props}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<CloseButton />}>
                            <FormattedMessage
                                description="Share project dialog title."
                                defaultMessage="Share project"
                            />
                        </DialogTitle>
                        <DialogContent className={styles.dialogContent}>
                            <div className={styles.textDarker}>
                                <FormattedMessage
                                    description="Text on the Share project dialog. {PROJECT_TITLE} is the title of the project."
                                    defaultMessage="Anyone you share these links with will be able to view your work for project ''{PROJECT_TITLE}''."
                                    values={{ PROJECT_TITLE: title }}
                                />
                            </div>
                            <Field
                                className={styles.dialogField}
                                label={intl.formatMessage({
                                    description: 'Label for the project code URL text box.',
                                    defaultMessage: 'Source code',
                                })}
                            >
                                <div className={styles.flexRowSmallGap}>
                                    <Input
                                        className={styles.flexGrow1}
                                        readOnly
                                        value={sharedLinkUrl}
                                        placeholder={intl.formatMessage({
                                            description: 'Instructions in the project code URL text box.',
                                            defaultMessage:
                                                "Click the share button to create a link to project's source code.",
                                        })}
                                    />
                                    {sharedLinkUrl === '' ? (
                                        <TrackedButton
                                            trackAction="create-shared-link"
                                            onClick={() => dispatch({ type: 'CreateSharedLink', projectId })}
                                            disabled={state === 'Updating'}
                                            icon={<ShareRegular />}
                                            title={intl.formatMessage({
                                                description: 'Tooltip for the Create link button.',
                                                defaultMessage: 'Create a shared link for this project.',
                                            })}
                                        />
                                    ) : (
                                        <>
                                            <TrackedButton
                                                trackAction="delete-shared-link"
                                                onClick={() => setStopSharingOpen(true)}
                                                disabled={state === 'Updating'}
                                                icon={<ShareScreenStopRegular />}
                                                title={intl.formatMessage({
                                                    description: 'Tooltip for the Delete link button.',
                                                    defaultMessage: 'Delete the link to stop sharing this project.',
                                                })}
                                            />
                                            <CopyToClipboardButton
                                                disabled={state === 'Updating'}
                                                text={sharedLinkUrl}
                                                appearance="secondary"
                                                link
                                            />
                                        </>
                                    )}
                                </div>
                            </Field>
                            {publicUrl !== undefined && (
                                <Field
                                    className={styles.dialogField}
                                    label={intl.formatMessage({
                                        description: 'Label for the published project URL text box.',
                                        defaultMessage: 'Published web site',
                                    })}
                                >
                                    <div className={styles.flexRowSmallGap}>
                                        <Input className={styles.flexGrow1} value={publicUrl} readOnly />
                                        <CopyToClipboardButton text={publicUrl!} appearance="secondary" link />
                                    </div>
                                </Field>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger action="close">
                                <TrackedButton trackAction="close" appearance="secondary">
                                    <FormattedMessage
                                        description="Text on the dialog Close button"
                                        defaultMessage="Close"
                                    />
                                </TrackedButton>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <StopSharingDialog
                open={stopSharingOpen}
                onOpenChange={(_, { open }) => setStopSharingOpen(open)}
                title={title}
                onConfirm={() => dispatch({ type: 'DeleteSharedLink', projectId })}
            />
        </TrackContext.Provider>
    );
});
