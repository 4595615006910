import React, { useState } from 'react';
import { TrackContext } from '../components/common';
import SignInDialog from '../components/identity/sign-in-dialog';
import PageContainer from '../components/page-container';
import { Redirecting, VSCodeEduIdentityProvider } from '../data/vsce-identity-provider';
import { useAppStyles } from '../styles';

// Signin page properties.
export type SigninProps = {
    readonly redirectTo?: string;
};

// Standalone sign-in page.
export default (props: SigninProps) => {
    const appStyles = useAppStyles();
    const [open, setOpen] = useState(true);
    return (
        <TrackContext.Provider value={{ container: 'sign-in' }}>
            <PageContainer className={appStyles.pageContainer}>
                <SignInDialog
                    open={open}
                    onSignIn={async (provider, rememberMe) => {
                        try {
                            setOpen(false);
                            const authProvider = new VSCodeEduIdentityProvider();
                            await authProvider.signIn(provider, rememberMe, props.redirectTo);
                        } catch (e) {
                            // Ignore redirection errors.
                            if (!(e instanceof Redirecting)) {
                                throw e;
                            }
                        }
                    }}
                />
            </PageContainer>
        </TrackContext.Provider>
    );
};
