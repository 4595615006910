import { VsCodeEduClientExt, assert, compareBooleans, compareLocale } from '@microsoft/vscodeedu-api';
import { Browser } from '@microsoft/vscodeedu-zip-util/dist/browser';
import { produce } from 'immer';
import React, { Dispatch, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { ContextProps, StoreState, anonymousClient, useConfig } from '.';
import { Project, ProjectTemplate, ProjectTemplateFiles } from '../models';
import { getContentUrl } from '../utilities';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';

// Project templates store.
export type ProjectTemplateStore = Readonly<{
    state: StoreState;
    items: ProjectTemplate[];
}>;

// Project template store action.
export type ProjectTemplateAction =
    | { type: 'Load' }
    | { type: 'LoadResult'; items: ProjectTemplate[] }
    | { type: 'LoadError' };

// Project templates context.
export const ProjectTemplateContext = createContext<
    [state: ProjectTemplateStore, reducer: Dispatch<ProjectTemplateAction>]
>(undefined!);

// Project templates context provider.
export const ProjectTemplateContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const { visibleProjectTemplates, contentBaseUrl } = useConfig();
    const runner = useAsyncRunner();

    // Lists all available project templates.
    const listTemplates = useCallback(
        async () =>
            runner.run({
                task: async () => {
                    const items = await anonymousClient.listProjectTemplates();

                    // Filter project templates based on config settings.
                    const filtered = !visibleProjectTemplates.length
                        ? items
                        : items.filter((o) => visibleProjectTemplates.includes(o.id));

                    dispatch({
                        type: 'LoadResult',
                        items: filtered.map((o) => ({
                            ...o,
                            icon: getContentUrl(contentBaseUrl, o.icon),
                        })),
                    });
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading list of project templates.',
                }),
            }),
        [runner, intl, visibleProjectTemplates, contentBaseUrl]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: ProjectTemplateStore, action: ProjectTemplateAction) =>
            produce(store, (draft) => {
                traceEvent('project-template-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'Load': {
                        if (draft.state === 'NotLoaded') {
                            listTemplates();
                            draft.state = 'Loading';
                        }
                        break;
                    }
                    case 'LoadResult':
                        draft.state = 'Loaded';
                        draft.items = action.items;
                        break;
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [listTemplates]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'NotLoaded', items: [] });
    return (
        <ProjectTemplateContext.Provider value={[state, dispatch]}>{props.children}</ProjectTemplateContext.Provider>
    );
});

// Use project templates context.
export const useProjectTemplates = () => {
    const [store, dispatch] = useContext(ProjectTemplateContext);
    useEffect(() => dispatch({ type: 'Load' }), [dispatch]);
    return [store, dispatch] as [typeof store, typeof dispatch];
};

// Selector to get sorted list of project templates.
export const getSortedTemplates = createSelector(
    (store: ProjectTemplateStore) => store.items,
    (items) => {
        return [...items].sort((a, b) => {
            // Featured templates (inverse to bring greater true values to the front of the list) first, then sort by title.
            return compareBooleans(b.featured, a.featured) || compareLocale(a.title, b.title);
        });
    }
);

// Replaces project title and descriptions tokens in template files' content with actual values.
export async function replaceTokens(
    client: VsCodeEduClientExt,
    template: ProjectTemplate,
    project: Partial<Project>
): Promise<ProjectTemplateFiles> {
    const result: ProjectTemplateFiles = {};

    let escape: (input: string) => string;
    switch (template.type) {
        case 'html':
            escape = (input) =>
                input.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            break;
        case 'python':
            escape = (input) => input.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
            break;
        default:
            escape = (input) => input;
            break;
    }

    const title = escape(project.title ?? '');
    const description = escape(project.description ?? '');

    const zip = await client.downloadProjectTemplateDrive(template.id);
    for (const [name, file] of await Browser.unzip(await zip.blobBody!)) {
        if (!file.isDirectory) {
            let content = await (await file.content()).text();
            content = content.replace(/{PROJECT_TITLE}/g, title);
            content = content.replace(/{PROJECT_DESCRIPTION}/g, description);
            result[name] = content;
        }
    }

    return result;
}
