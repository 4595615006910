import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    Field,
    Input,
    Textarea,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MaxDescriptionLength, MaxTitleLength } from '../../models';
import { useAppStyles } from '../../styles';
import { IntlProps, TrackContext } from '../common';
import { CancelButton, ConfirmButton, DialogHeader } from '../dialogs';

// Update project dialog properties.
export type UpdateProjectDialogProps = IntlProps<
    Partial<DialogProps> & {
        initialTitle: string;
        initialDescription?: string;
        onUpdate: (title: string, description?: string) => void;
    }
>;

// Update project dialog.
export default injectIntl((props: UpdateProjectDialogProps) => {
    const { intl, initialTitle, initialDescription, onUpdate } = props;
    const styles = useAppStyles();

    const [title, setTitle] = useState('');
    useEffect(() => setTitle(initialTitle ?? ''), [initialTitle]);

    const [description, setDescription] = useState('');
    useEffect(() => setDescription(initialDescription ?? ''), [initialDescription]);

    const onConfirm = useCallback(() => {
        const trimmed = description?.trim();
        onUpdate(title.trim(), trimmed?.length > 0 ? trimmed : undefined);
    }, [title, description, onUpdate]);

    return (
        <TrackContext.Provider value={{ container: 'update-project-dialog' }}>
            <Dialog {...props}>
                <DialogSurface className={styles.dialogNarrow}>
                    <DialogBody>
                        <DialogHeader
                            title={intl.formatMessage({
                                description: 'Update project dialog title.',
                                defaultMessage: 'Update project',
                            })}
                        />
                        <DialogContent>
                            <Field
                                className={styles.dialogField}
                                required
                                label={intl.formatMessage({
                                    description: 'Label for the project title text box.',
                                    defaultMessage: 'Project title',
                                })}
                            >
                                <Input
                                    value={title}
                                    onChange={(_, { value }) => setTitle(value)}
                                    maxLength={MaxTitleLength}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the project title text box.',
                                        defaultMessage: 'Name your project',
                                    })}
                                />
                            </Field>
                            <Field
                                className={styles.dialogField}
                                label={intl.formatMessage({
                                    description: 'Label for the project description text box.',
                                    defaultMessage: 'Project description',
                                })}
                            >
                                <Textarea
                                    value={description}
                                    onChange={(_, { value }) => setDescription(value)}
                                    maxLength={MaxDescriptionLength}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the project description text box.',
                                        defaultMessage: 'Tell us about your project...',
                                    })}
                                />
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton />
                            <ConfirmButton appearance="primary" disabled={!title.trim()} onClick={onConfirm}>
                                <FormattedMessage
                                    description="Update project dialog confirm button"
                                    defaultMessage="Update project"
                                />
                            </ConfirmButton>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
});
