import { Toaster, makeStyles } from '@fluentui/react-components';
import { isAbsoluteUrl, isNonEmptyString } from '@microsoft/vscodeedu-api';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from './components/footer';
import Header from './components/header/header';
import SignInDialog from './components/identity/sign-in-dialog';
import ReportDialog from './components/report-dialog';
import { UserContext } from './data';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
});

export type SignInContextValue = Readonly<{
    ensureSignedIn: (navigateTo?: string) => void;
}>;

export const SignInContext = createContext<SignInContextValue>({ ensureSignedIn: () => {} });

export default () => {
    const styles = useStyles();
    const [store, dispatch] = useContext(UserContext);
    const [signInDialogOpen, setSignInDialogOpen] = useState(false);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const location = useLocation();

    const ensureSignedIn = useCallback(
        (navigateTo?: string) => {
            if (!store.user) {
                setNavigateTo(navigateTo);
                setSignInDialogOpen(true);
            } else if (isNonEmptyString(navigateTo)) {
                if (isAbsoluteUrl(navigateTo)) {
                    window.location.href = navigateTo;
                } else {
                    navigate(navigateTo);
                }
            }
        },
        [navigate, store.user]
    );

    useEffect(() => {
        if (location.pathname === '/report') {
            setReportDialogOpen(true);
        }
    }, [location]);

    return (
        <>
            <div className={styles.container}>
                <SignInContext.Provider value={{ ensureSignedIn }}>
                    <Header />
                    <Outlet />
                    <Toaster toasterId="app-toaster" position="bottom" pauseOnHover pauseOnWindowBlur />
                    <Footer />
                </SignInContext.Provider>
                <SignInDialog
                    open={signInDialogOpen}
                    onOpenChange={(_, { open }) => setSignInDialogOpen(open)}
                    onSignIn={(provider, rememberMe, signUp) =>
                        dispatch({ type: 'SignIn', provider, rememberMe, signUp, navigateTo })
                    }
                />
                <ReportDialog open={reportDialogOpen} onOpenChange={(_, { open }) => setReportDialogOpen(open)} />
            </div>
        </>
    );
};
