import { DifficultyLevel } from '@microsoft/vscodeedu-api';
import React, { HTMLProps } from 'react';
import { FormattedMessage } from 'react-intl';

// Difficulty level properties.
export type DifficultyLabelProps = Readonly<
    HTMLProps<HTMLDivElement> & {
        level?: DifficultyLevel;
    }
>;

// Difficulty level label.
export default (props: DifficultyLabelProps) => {
    const { level } = props;
    switch (level) {
        case 'beginner':
            return (
                <div {...props}>
                    <FormattedMessage description="Difficulty indicator text" defaultMessage="Beginner" />
                </div>
            );
        case 'intermediate':
            return (
                <div {...props}>
                    <FormattedMessage description="Difficulty indicator text" defaultMessage="Intermediate" />
                </div>
            );
        default:
            return (
                <div {...props}>
                    <FormattedMessage description="Difficulty indicator text" defaultMessage="Advanced" />
                </div>
            );
    }
};
