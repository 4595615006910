import { TableCellLayout, makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import { Project } from '../../models';
import { ProjectTypeLabel } from '../common';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
        minHeight: '4.8rem',
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    type: {
        color: tokens.colorNeutralForeground2,
        fontVariantCaps: 'all-small-caps',
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        paddingBottom: tokens.spacingVerticalXS,
    },
    description: {
        paddingTop: tokens.spacingVerticalXS,
        maxHeight: '2rem',
        color: tokens.colorNeutralForeground4,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
});

// Name cell properties.
export type NameCellProps = Readonly<{
    type: Project['type'];
    title: string;
    description?: string;
}>;

// Project name data grid cell layout.
export default (props: NameCellProps) => {
    const { type, title, description } = props;
    const styles = useStyles();

    return (
        <TableCellLayout className={styles.root}>
            <ProjectTypeLabel className={styles.type} type={type} />
            <div>{title}</div>
            <div className={styles.description}>{description}</div>
        </TableCellLayout>
    );
};
