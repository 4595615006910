import { Image, makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { getFetchUrl } from '../../utilities';
import { IntlProps } from '../common';
import { useTheme } from '../../data/theme-context';

const useStyles = makeStyles({
    image: {
        width: '7rem',
        height: '1.5rem',
    },
    navLink: {
        '&:focus-visible': {
            outline: `2px solid ${tokens.colorStrokeFocus2}`,
        },
    },
});

// Microsoft logo home link.
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useStyles();
    const [{ isDarkTheme }] = useTheme();

    const title = intl.formatMessage({
        description: 'Title text for homepage link',
        defaultMessage: 'Visual Studio Code for Education',
    });

    const logoLink = isDarkTheme ? '/microsoft-logo-color.png' : '/microsoft-logo-color-dark.png';

    return (
        <NavLink className={styles.navLink} to="/" title={title}>
            <Image className={styles.image} src={getFetchUrl(logoLink)} alt={title} />
        </NavLink>
    );
});
