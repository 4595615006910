import {
    Toast,
    ToastBody,
    ToastTitle,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
    useToastController,
} from '@fluentui/react-components';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { IntlProps, LoadingCard, TrackContext, TrackedButton } from '../components/common';
import CourseCard from '../components/course-card/course-card';
import PageContainer from '../components/page-container';
import { CourseContext, getCourse, getIsLoadingCourses, useConfig } from '../data';
import { MOBILE_AND_BELOW, TABLET_AND_BELOW, useAppStyles } from '../styles';
import { getFetchUrl } from '../utilities';

const useStyle = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        [TABLET_AND_BELOW]: {
            flexDirection: 'column',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2rem',
        justifyContent: 'center',
        [TABLET_AND_BELOW]: {
            width: '100%',
            height: 'unset',
        },
    },
    video: {
        width: '46.6rem',
        height: '35rem',
        maskImage:
            'radial-gradient(75.34% 103.91% at 75.9% 46.07%, #F8F8F8 0%, #F8F8F8 61.17%, rgba(248, 248, 248, 25.67) 64.17%, rgba(248, 248, 248, 0.00) 100%)',
        [TABLET_AND_BELOW]: {
            width: '100%',
            height: 'unset',
            maskImage: 'unset',
            ...shorthands.padding(tokens.spacingVerticalL, 'unset'),
        },
    },
    title: {
        fontSize: '3.875rem',
        lineHeight: '4.8rem',
        fontWeight: tokens.fontWeightSemibold,
        [MOBILE_AND_BELOW]: {
            fontSize: tokens.fontSizeHero900,
            lineHeight: tokens.lineHeightHero900,
        },
    },
    subtitle: {
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightBase500,
    },
});

// Home page.
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useStyle();
    const appStyles = useAppStyles();
    const navigate = useNavigate();
    const [store] = useContext(CourseContext);
    const isLoading = getIsLoadingCourses(store);
    const { heroCourses } = useConfig();
    const courses = heroCourses?.split(',').map((o) => getCourse(store, o)) ?? [];

    const { state: routeState } = useLocation();
    const { dispatchToast } = useToastController('app-toaster');
    useEffect(() => {
        if (routeState === 'signin-error') {
            dispatchToast(
                <Toast appearance="inverted">
                    <ToastTitle>
                        <FormattedMessage
                            description="Error message in case of sign-in failure."
                            defaultMessage="An error occurred while trying to sign in."
                        />
                    </ToastTitle>
                    <ToastBody>
                        <FormattedMessage
                            description="Text on the error toast UI."
                            defaultMessage="Please ensure you are using correct account, and your computer is connected to the Internet, then try again."
                        />
                    </ToastBody>
                </Toast>,
                { intent: 'error', timeout: 5000 }
            );
        } else if (routeState === 'sharelink-error') {
            dispatchToast(
                <Toast appearance="inverted">
                    <ToastTitle>
                        <FormattedMessage
                            description="Error message in case of shared link failure."
                            defaultMessage="An error occurred while trying to load the shared link."
                        />
                    </ToastTitle>
                    <ToastBody>
                        <FormattedMessage
                            description="Text on the error toast UI."
                            defaultMessage="Please ensure you are using the correct share link, the link is still shared, and your computer is connected to the Internet, then try again."
                        />
                    </ToastBody>
                </Toast>,
                { intent: 'error', timeout: 5000 }
            );
        }
    }, [dispatchToast, routeState]);

    return (
        <TrackContext.Provider value={{ container: 'home' }}>
            <PageContainer className={appStyles.pageContainer}>
                <div className={mergeClasses(styles.container, 'content-width')}>
                    <div className={styles.content}>
                        <div className={appStyles.flexColumnSmallGap}>
                            <div className={styles.title}>
                                <FormattedMessage
                                    description="Title text on the home page"
                                    defaultMessage="Visual Studio Code for Education"
                                />
                            </div>
                            <div className={styles.subtitle}>
                                <FormattedMessage
                                    description="Text on the home page"
                                    defaultMessage="Powering the next generation of developers"
                                />
                            </div>
                        </div>
                        <div className={appStyles.flexRowSmallGap}>
                            <TrackedButton
                                trackAction="explore-activities"
                                className={mergeClasses(appStyles.button, appStyles.buttonPrimary)}
                                onClick={() => navigate('/courses')}
                            >
                                <FormattedMessage
                                    description="Button text on the home page"
                                    defaultMessage="Explore activities"
                                />
                            </TrackedButton>
                            <TrackedButton
                                trackAction="start-project"
                                appearance="secondary"
                                className={mergeClasses(appStyles.button)}
                                onClick={() => navigate('/my-work/projects/new')}
                            >
                                <FormattedMessage
                                    description="Button text on the home page"
                                    defaultMessage="Start a project"
                                />
                            </TrackedButton>
                        </div>
                    </div>
                    <video
                        className={styles.video}
                        autoPlay
                        muted
                        playsInline
                        loop
                        disablePictureInPicture={true}
                        src={getFetchUrl('/home-demo-v2.mp4')}
                        title={intl.formatMessage({
                            description: 'Home page looping video alt text',
                            defaultMessage:
                                'Looping animation of vscode.dev with an introductory Python course open that demonstrates how to run code.',
                        })}
                    >
                        <track kind="captions"></track>
                    </video>
                </div>
                <div className={appStyles.pageContent}>
                    <div className={mergeClasses(appStyles.section, 'content-width')}>
                        <div className={appStyles.flexRow}>
                            <div className={mergeClasses(appStyles.sectionTitle, appStyles.flexGrow1)}>
                                <FormattedMessage
                                    description="Section title for the course list"
                                    defaultMessage="Featured Activities"
                                />
                            </div>
                            <div className={mergeClasses(appStyles.flexGrow0)}>
                                <TrackedButton
                                    trackAction="explore-more"
                                    appearance="secondary"
                                    className={mergeClasses(appStyles.button)}
                                    onClick={() => navigate('/courses')}
                                >
                                    <FormattedMessage
                                        description="Button text on the course list"
                                        defaultMessage="Explore more"
                                    />
                                </TrackedButton>
                            </div>
                        </div>
                        <div className={mergeClasses(appStyles.sectionContent, appStyles.cardsContainer)}>
                            {isLoading && <LoadingCard showImage />}
                            {!isLoading &&
                                courses.map((o) =>
                                    !o ? undefined : (
                                        <CourseCard
                                            {...o}
                                            tags={undefined}
                                            key={o!.id}
                                            className={appStyles.cardThreeColumns}
                                            onClick={() => navigate(`/courses/${o!.id}`)}
                                        />
                                    )
                                )}
                        </div>
                    </div>
                </div>
            </PageContainer>
        </TrackContext.Provider>
    );
});
