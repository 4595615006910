import { DifficultyLevel } from '@microsoft/vscodeedu-api';
import React from 'react';
import { BeginnerIcon, ExpertIcon, IntermediateIcon } from '../../icons/difficulty';
import { IconProps } from '../../icons/icon-props';

// Difficulty level icon properties.
export type DifficultyIconProps = IconProps<{
    level?: DifficultyLevel;
}>;

// Difficulty level icon.
export default (props: DifficultyIconProps) => {
    switch (props.level) {
        case 'beginner':
            return <BeginnerIcon {...props} />;
        case 'intermediate':
        case 'advanced':
            return <IntermediateIcon {...props} />;
        case 'expert':
            return <ExpertIcon {...props} />;
        default:
            return <></>;
    }
};
