import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CloudStorageImage } from '../../icons/status';
import { useAppStyles } from '../../styles';

// Published projects empty state.
export default () => {
    const styles = useAppStyles();

    return (
        <div className={styles.emptyState}>
            <CloudStorageImage className={styles.emptyStateImage} />
            <FormattedMessage
                description="Empty published projects list message"
                defaultMessage="There are currently no projects published to the web."
            />
        </div>
    );
};
