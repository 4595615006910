import { Spinner, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { CheckmarkRegular, ErrorCircleFilled, WarningRegular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        columnGap: tokens.spacingHorizontalXS,
    },
    default: {},
    subtle: {
        color: tokens.colorNeutralForeground4,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        height: tokens.lineHeightBase200,
        maxHeight: tokens.lineHeightBase200,
    },
    text: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
    },
    error: {
        color: tokens.colorPaletteRedForeground1,
    },
});

// Indicator properties.
export type IndicatorProps<T = unknown> = Readonly<
    React.HTMLAttributes<HTMLDivElement> & {
        type?: 'default' | 'spinner' | 'success' | 'warning' | 'error';
        appearance?: 'default' | 'subtle';
        icon?: JSX.Element | false;
        text?: string | JSX.Element | false;
    } & T
>;

// Icon indicator with text.
export default (props: IndicatorProps) => {
    const { type, appearance, icon, text, children } = props;
    const styles = useStyles();

    const rootStyle = useMemo(
        () => mergeClasses(styles.root, appearance === 'subtle' ? styles.subtle : styles.default, props.className),
        [styles.root, styles.subtle, styles.default, appearance, props.className]
    );

    const textStyle = useMemo(
        () => mergeClasses(styles.text, type === 'error' && styles.error),
        [styles.text, styles.error, type]
    );

    return (
        <div className={rootStyle} {...props}>
            {(!type || type === 'default') && icon}
            {type === 'spinner' && <Spinner size="tiny" aria-hidden />}
            {type === 'success' && <CheckmarkRegular color={tokens.colorPaletteGreenForeground1} />}
            {type === 'warning' && <WarningRegular color={tokens.colorPaletteYellowForeground1} />}
            {type === 'error' && <ErrorCircleFilled color={tokens.colorPaletteRedForeground1} />}
            {text !== undefined && <span className={textStyle}>{text}</span>}
            {children !== undefined && <span className={textStyle}>{children}</span>}
        </div>
    );
};
