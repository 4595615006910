import { isNonEmptyString } from '@microsoft/vscodeedu-api';

// Invokes browser download of the blob.
export function downloadBlob(blob: Blob, filename?: string) {
    const url = window.URL.createObjectURL(blob);
    downloadUrl(url, filename);
}

// Invokes browser download of the file from specified URL.
export function downloadUrl(url: string, filename?: string) {
    const link = document.createElement('a');
    link.href = url;
    if (isNonEmptyString(filename)) {
        link.download = filename;
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
}
