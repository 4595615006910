import { DialogTitle, DialogTrigger } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps, TrackedButton } from '../common';

// Dialog header properties.
export type DialogHeaderProps = IntlProps<{
    title: string | JSX.Element;
    onCloseClicked?: () => void;
}>;

// Dialog header.
export default injectIntl((props: DialogHeaderProps) => {
    const { intl, title, onCloseClicked } = props;
    return (
        <DialogTitle
            action={
                <DialogTrigger action="close">
                    <TrackedButton
                        trackAction="close"
                        tabIndex={-1}
                        appearance="subtle"
                        aria-label={intl.formatMessage({
                            description: 'Dialog close button aria label',
                            defaultMessage: 'Close',
                        })}
                        icon={<DismissRegular />}
                        onClick={onCloseClicked}
                    />
                </DialogTrigger>
            }
        >
            {title}
        </DialogTitle>
    );
});
