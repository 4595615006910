import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Post } from '../../models';
import { useAppStyles } from '../../styles';
import { TrackedButton } from '../common';
import TimeAgoLabel from '../common/time-ago-label';
import PostCategoryLabel from './post-category-label';

const useStyles = makeStyles({
    root: {
        flexDirection: 'row',
        minHeight: '25rem',
        ...shorthands.padding(tokens.spacingHorizontalXS),
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding(tokens.spacingHorizontalXL),
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: tokens.spacingVerticalL,
    },
});

// Pinned post card properties.
export type PinnedPostCardProps = Readonly<{
    post: Post;
}>;

// Pinned post card.
export default (props: PinnedPostCardProps) => {
    const { post } = props;
    const { id, category, title, summary, cardImage, publishedDate } = post;
    const styles = useStyles();
    const appStyles = useAppStyles();
    const navigate = useNavigate();

    return (
        <div className={mergeClasses(appStyles.card, styles.root)}>
            <div className={mergeClasses(appStyles.flexEqual, styles.contentContainer)}>
                <div className={styles.content}>
                    <PostCategoryLabel className={appStyles.cardCategory} category={category} />
                    <div className={appStyles.cardTitleHero}>{title}</div>
                    <div>{summary}</div>
                    <div>
                        <TrackedButton
                            trackAction="read-more"
                            role="link"
                            className={mergeClasses(appStyles.buttonMedium, appStyles.buttonPrimary)}
                            onClick={() => navigate(id)}
                        >
                            <FormattedMessage description="Button text" defaultMessage="Read more" />
                        </TrackedButton>
                    </div>
                </div>
                <TimeAgoLabel className={appStyles.cardFooter} date={publishedDate} />
            </div>
            <div
                className={mergeClasses(appStyles.cardMediaImage, appStyles.flexEqual, appStyles.mobileHidden)}
                style={{ backgroundImage: `url(${cardImage})` }}
            />
        </div>
    );
};
