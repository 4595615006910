import React, { HTMLProps } from 'react';
import { FormattedMessage } from 'react-intl';
import { PostCategory } from '../../models';

// Post category label properties.
export type PostCategoryLabelProps = Readonly<
    HTMLProps<HTMLDivElement> & {
        category: PostCategory;
    }
>;

// Post category label.
export default (props: PostCategoryLabelProps) => {
    const { type } = props;
    switch (type) {
        case 'improved':
            return (
                <div {...props}>
                    <FormattedMessage description="'Improved' post type" defaultMessage="Improved" />
                </div>
            );
        default:
            return (
                <div {...props}>
                    <FormattedMessage description="Post category" defaultMessage="Update" />
                </div>
            );
    }
};
