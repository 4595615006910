import { shorthands, tokens } from '@fluentui/react-components';
import { defineStyles } from './define-styles';
import { MOBILE_AND_BELOW } from './responsive';

const dialogImageSize = '7rem';

// Dialog styles.
export const dialogStyles = defineStyles({
    // Dialog content.
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
        ...shorthands.padding(tokens.spacingHorizontalM),
    },
    // Dialog field.
    dialogField: {
        width: '100%',
        marginBottom: tokens.spacingVerticalL,
    },
    // Narrow dialog width.
    dialogNarrow: {
        width: '32rem',
    },
    // Status image on the left side of a dialog.
    dialogImage: {
        alignSelf: 'start',
        width: dialogImageSize,
        minWidth: dialogImageSize,
        height: dialogImageSize,
        minHeight: dialogImageSize,
        [MOBILE_AND_BELOW]: {
            display: 'none',
        },
    },
});
