import { assert, compareLocale, isNotFoundError, models } from '@microsoft/vscodeedu-api';
import { produce } from 'immer';
import React, { Dispatch, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { ContextProps, CourseContext, StoreState, UserContext, getIsSignedIn, getUserClient } from '.';
import { Course, UserProgress } from '../models';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';
import { downloadBlob } from '../utilities/download';

// User progress store.
export type UserProgressStore = Readonly<{
    state: StoreState;
    items: UserProgress[];
    data: models.UserProgress[];
}>;

// User progress store action.
export type UserProgressAction =
    | { type: 'Load' }
    | { type: 'LoadResult'; data: models.UserProgress[] | undefined }
    | { type: 'LoadError' }
    | { type: 'Reload' }
    | { type: 'CoursesChanged' }
    | { type: 'Unenroll'; courseId: string }
    | { type: 'UnenrollResult'; courseId: string }
    | { type: 'UnenrollError'; courseId: string }
    | { type: 'Download'; courseId: string };

// User progress context.
export const UserProgressContext = createContext<[state: UserProgressStore, reducer: Dispatch<UserProgressAction>]>(
    undefined!
);

// User progress context provider.
export const UserProgressContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const [store] = useContext(UserContext);
    const isSignedIn = getIsSignedIn(store);
    const client = getUserClient(store);
    const runner = useAsyncRunner();

    // Respond to user change by reloading the data.
    useEffect(() => dispatch({ type: 'Reload' }), [isSignedIn]);

    // Matches progress data to courses and sends results back to dispatch.
    const [{ items: courses }] = useContext(CourseContext);
    const matchCourseData = useCallback(
        (data: models.UserProgress[]) =>
            data
                .map((o) => {
                    const course = courses.find(({ id }) => id === o.courseId);
                    return course ? toModel(o, course) : undefined;
                })
                .filter((o) => !!o) as UserProgress[],
        [courses]
    );

    // Respond to course changes by mapping progress to units.
    useEffect(() => {
        if (isSignedIn) {
            dispatch({ type: 'CoursesChanged' });
        }
    }, [isSignedIn, courses]);

    // Lists all available course progress, and updates the store.
    const listProgressData = useCallback(
        async () =>
            runner.run({
                task: async () => {
                    if (client) {
                        const data = await client.listUserProgress('me');
                        dispatch({ type: 'LoadResult', data });
                    }
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading course progress information.',
                }),
            }),
        [runner, intl, client]
    );

    // Deletes a course progress, and updates the store.
    const deleteProgress = useCallback(
        async (item: UserProgress) => {
            const { id, title } = item.course;
            runner.run({
                task: async () => {
                    await client!.deleteUserProgress('me', id);
                    dispatch({ type: 'UnenrollResult', courseId: id });
                },
                onError: () => {
                    dispatch({ type: 'UnenrollError', courseId: id });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Unenrolling from course {COURSE_TITLE}.',
                    },
                    { COURSE_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully unenrolled from course {COURSE_TITLE}.',
                    },
                    { COURSE_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while unenrolling from course {COURSE_TITLE}.',
                    },
                    { COURSE_TITLE: title }
                ),
            });
        },
        [client, intl, runner]
    );

    // Downloads user code.
    const downloadProgress = useCallback(
        async (item: UserProgress) => {
            const { id, title } = item.course;
            runner.run({
                task: async () => {
                    const zip = await client!.downloadUserProgressDrive('me', id);
                    if (zip.blobBody) {
                        downloadBlob(await zip.blobBody, `${id}.zip`);
                    }
                },
                onError: (error) =>
                    isNotFoundError(error)
                        ? intl.formatMessage({
                              description: 'Error message for an async operation.',
                              defaultMessage: 'The course does not contain any completed coding exercises to download.',
                          })
                        : undefined,
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Downloading information for course {COURSE_TITLE}.',
                    },
                    { COURSE_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while downloading information for course {COURSE_TITLE}.',
                    },
                    { COURSE_TITLE: title }
                ),
            });
        },
        [client, intl, runner]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: UserProgressStore, action: UserProgressAction) =>
            produce(store, (draft) => {
                traceEvent('user-progress-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'Load':
                        if (draft.state === 'NotLoaded') {
                            listProgressData();
                            draft.state = 'Loading';
                        }
                        break;
                    case 'Reload':
                        if (draft.state !== 'Loading' && draft.state !== 'NotLoaded') {
                            listProgressData();
                            draft.state = 'Loading';
                        }
                        break;
                    case 'LoadResult':
                        if (action.data !== undefined) {
                            draft.state = 'Loaded';
                            draft.data = action.data;
                            draft.items = matchCourseData(action.data);
                        } else {
                            draft.state = 'NotLoaded';
                            draft.data = [];
                        }
                        break;
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    case 'CoursesChanged':
                        draft.items = matchCourseData(draft.data);
                        break;
                    case 'Unenroll': {
                        const item = draft.items.find((o) => o.courseId === action.courseId);
                        if (item) {
                            deleteProgress(item);
                            item.state = 'Deleting';
                        }
                        break;
                    }
                    case 'UnenrollResult': {
                        const index = draft.items.findIndex((o) => o.courseId === action.courseId);
                        if (index >= 0) {
                            draft.items.splice(index, 1);
                        }
                        break;
                    }
                    case 'UnenrollError': {
                        const item = draft.items.find((o) => o.courseId === action.courseId);
                        if (item) {
                            item.state = 'Created';
                        }
                        break;
                    }
                    case 'Download': {
                        const item = draft.items.find((o) => o.courseId === action.courseId);
                        if (item) {
                            downloadProgress(item);
                        }
                        break;
                    }
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [matchCourseData, listProgressData, deleteProgress, downloadProgress]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'NotLoaded', items: [], data: [] });
    return <UserProgressContext.Provider value={[state, dispatch]}>{props.children}</UserProgressContext.Provider>;
});

// Use current user's course progress.
export const useUserProgress = () => {
    const [store, dispatch] = useContext(UserProgressContext);
    useEffect(() => dispatch({ type: 'Load' }), [dispatch]);
    return [store, dispatch] as [typeof store, typeof dispatch];
};

// Selector that returns whether the user progress store is loaded.
export const getIsLoadingProgress = createSelector(
    [(store: UserProgressStore) => store.state],
    (state) => state !== 'Loaded'
);

// Selector that gets user progress sorted by course title.
export const getSortedUserProgress = createSelector([(store: UserProgressStore) => store.items], (items) =>
    [...items].sort((a, b) => compareLocale(a.course?.title, b.course?.title))
);

// Selector to get whether user has started working on specified course.
export const getIsCourseStarted = createSelector(
    [(store: UserProgressStore, _) => store.items, (_, courseId: string) => courseId],
    (items, courseId) => items.find((o) => o.courseId === courseId)?.started === true
);

// Updates view model with course information.
export function toModel(data: models.UserProgress, course?: Course): UserProgress {
    let result = { ...data, state: 'Created' } as UserProgress;

    if (!course) {
        return result;
    }

    result = { ...result, course };
    if (!course.units) {
        return result;
    }

    let started = false;
    let completedUnits = 0;
    let current: string | undefined;
    for (const unit of course.units) {
        if (!unit.lessons) {
            completedUnits++;
            continue;
        }

        const unitProgress = data.units?.find((u) => u.unitId === unit.id);
        if (!unitProgress) {
            continue;
        }

        current = unit.title;
        let completedLessons = 0;
        for (const lesson of unit.lessons) {
            if (!lesson.parts) {
                completedLessons++;
                continue;
            }

            const lessonProgress = unitProgress.lessons?.find((l) => l.lessonId === lesson.id);
            if (!lessonProgress) {
                continue;
            }

            current = `${unit.title} ⏵ ${lesson.title}`;
            let completedParts = 0;
            for (const part of lesson.parts) {
                const partProgress = lessonProgress.parts?.find((p) => p.partId === part.id);
                if (!partProgress) {
                    continue;
                } else if (partProgress.percentComplete === 100) {
                    started = true;
                    completedParts++;
                } else if (partProgress.percentComplete) {
                    started = true;
                }
            }

            if (completedParts === lesson.parts.length) {
                completedLessons++;
            }
        }

        if (completedLessons === unit.lessons.length) {
            completedUnits++;
        }
    }

    return {
        ...result,
        started,
        completedUnits,
        totalUnits: course.units.length,
        currentPosition: current,
    };
}
