import { trackMetric } from './diagnostics';

const measures: PerformanceMeasure[] = [];

export async function timeAsync<U>(name: string, op: () => Promise<U>): Promise<U> {
    startMeasure(name);
    const result = await op();
    endMeasure(name);
    return result;
}

export function timeSync<U>(name: string, op: () => U): U {
    startMeasure(name);
    const result = op();
    endMeasure(name);
    return result;
}

export function startMeasure(name: string) {
    performance.clearMarks(startMark(name));
    performance.mark(startMark(name));
}

export function endMeasure(name: string) {
    performance.clearMarks(endMark(name));
    performance.mark(endMark(name));
    performance.clearMeasures(name);

    const measure = performance.measure(name, startMark(name), endMark(name));
    measures.push(measure);
    trackMetric('duration-' + name, measure.duration);
}

function startMark(name: string) {
    return name + '-start';
}

function endMark(name: string) {
    return name + '-end';
}

export function createReport(filterRegex?: RegExp) {
    const filtered = filterRegex ? measures.filter((m) => filterRegex.test(m.name)) : measures;

    let out = `Timing Report (duration in milliseconds):\n`;

    const maxNameLength = filtered.reduce((prev, current) => Math.max(prev, current.name.length), 0);

    for (const measure of filtered) {
        out += `  ${measure.name}:`.padEnd(maxNameLength + 5) + `${Math.round(measure.duration)}\n`;
    }

    return out.trim();
}
