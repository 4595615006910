import * as React from 'react';
import {
    DocumentPdfRegular,
    FolderZipRegular,
    ImageRegular,
    LinkRegular,
    PreviewLinkRegular,
    VideoClipRegular,
} from '@fluentui/react-icons';
import { ExcelDocumentIcon, PowerPointDocumentIcon, WordDocumentIcon } from '@fluentui/react-icons-mdl2-branded';

export type MimeTypeIconProps = {
    readonly mimeType: string;
};

export const MimeTypeIcon = (props: MimeTypeIconProps) => {
    // taken from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    const { mimeType } = props;

    if (mimeType.startsWith('text/')) {
        return <PreviewLinkRegular />;
    }

    if (mimeType.startsWith('video/')) {
        return <VideoClipRegular />;
    }

    if (mimeType.startsWith('image/')) {
        return <ImageRegular />;
    }

    switch (mimeType) {
        case 'application/msword':
        case 'application/vnd.google-apps.document':
            return <WordDocumentIcon />;

        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <PowerPointDocumentIcon />;

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <ExcelDocumentIcon />;

        case 'application/pdf':
            return <DocumentPdfRegular />;

        case 'application/zip':
        case 'application/x-7z-compressed':
        case 'application/x-tar':
        case 'application/gzip':
        case 'application/vnd.rar':
            return <FolderZipRegular />;

        case 'application/json':
        case 'application/xml':
            return <PreviewLinkRegular />;

        default:
            return <LinkRegular />;
    }
};
