import { combineUrls } from '@microsoft/vscodeedu-api';
import { routerBasename } from '../data';

// Converts content URL to an absolute URL that can be used in fetch() or <img src''>
export function getContentUrl(contentBaseUrl: string, url: string): string {
    return combineUrls(contentBaseUrl, url.replace(/^\$\//, '/'));
}

// Converts a relative URL to URL that can be used for React/browser routing purposes.
export function getRouteUrl(url: string): string {
    return combineUrls(routerBasename, url);
}

// Converts a relative resource URL to URL that can be used in a fetch() or <img src=''>
// to fetch a physical resource from the server.
export function getFetchUrl(url: string): string {
    return combineUrls(process.env.PUBLIC_URL, url);
}

// Converts a shared link URL.
export function getSharedLinkUrl(sharedLinkId: string, sharedLinkBaseUrl: string): string {
    return combineUrls(sharedLinkBaseUrl, sharedLinkId);
}
