import { SelectTabData, Tab, TabList, TabValue } from '@fluentui/react-components';
import { EducatorResource, Lesson, Standard } from '@microsoft/vscodeedu-api';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { useStandards } from '../../data';
import { useRenderer } from '../../utilities/markdown';
import { IntlProps } from '../common';
import EducatorResources from '../course-card/educator-resources';

// Lesson details properties.
type LessonDetailsProps = IntlProps<{
    lesson: Lesson;
}>;

// Lesson details section.
export default injectIntl((props: LessonDetailsProps) => {
    const { lesson, intl } = props;

    const [selectedTab, setSelectedTab] = useState<TabValue>('Objectives');
    const onTabSelect = useCallback((_: unknown, data: SelectTabData) => {
        setSelectedTab(data.value);
    }, []);

    const [{ items: standardMap }] = useStandards();
    const standardsMarkdown = useMemo(() => {
        const standards = lesson.standards?.map((id) => standardMap.get(id)).filter((o) => !!o);
        return getStandardsMarkdown((standards as Standard[]) ?? [], intl);
    }, [standardMap, lesson, intl]);

    const noStandardsMessage = intl.formatMessage({
        description: 'Table of contents empty standards placeholder',
        defaultMessage: 'There are no standards for this lesson.',
    });

    const noObjectivesMessage = intl.formatMessage({
        description: 'Table of contents empty objectives placeholder',
        defaultMessage: 'There are no objectives for this lesson.',
    });

    const noPrerequisitesMessage = intl.formatMessage({
        description: 'Table of contents empty prerequisites placeholder',
        defaultMessage: 'There are no prerequisites for this lesson.',
    });

    const noEducatorResourcesMessage = intl.formatMessage({
        description: 'Table of contents empty resources placeholder',
        defaultMessage: 'There are no resources for this lesson.',
    });

    const standards = useRenderer(standardsMarkdown ?? noStandardsMessage);
    const objectives = useRenderer(lesson.objectives ?? noObjectivesMessage);
    const prerequisites = useRenderer(lesson.prerequisites?.description ?? noPrerequisitesMessage);
    const educatorResources = getEducatorResources(lesson.educatorResources) ?? noEducatorResourcesMessage;

    return (
        <div>
            <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab id="Objectives" value="Objectives">
                    <FormattedMessage
                        description="Table of contents lesson details Objectives tab"
                        defaultMessage="Objectives"
                    />
                </Tab>
                <Tab id="Standards" value="Standards">
                    <FormattedMessage
                        description="Table of contents lesson details Standards tab"
                        defaultMessage="Standards"
                    />
                </Tab>
                <Tab id="Prerequisites" value="Prerequisites">
                    <FormattedMessage
                        description="Table of contents lesson details Prerequisites tab"
                        defaultMessage="Prerequisites"
                    />
                </Tab>
                <Tab id="Resources" value="Resources">
                    <FormattedMessage
                        description="Table of contents lesson details Resources tab"
                        defaultMessage="Resources"
                    />
                </Tab>
            </TabList>
            <div style={{ marginLeft: '1rem' }}>
                {selectedTab === 'Objectives' && objectives}
                {selectedTab === 'Standards' && standards}
                {selectedTab === 'Prerequisites' && prerequisites}
                {selectedTab === 'Resources' && educatorResources}
            </div>
        </div>
    );
});

// TODO: unify this with the extension code somehow
function getStandardsMarkdown(standards: Standard[], intl: IntlShape): string {
    const header = intl.formatMessage({
        description: 'Header for lesson standards markdown',
        defaultMessage: 'Targeted learning standards for this unit include:',
    });

    const standardStrings = standards.map((standard) => {
        return `* ${standard.id.slice(0, standard.id.indexOf('-')).toUpperCase()}-${standard.displayName}, ${
            standard.audience
        }: ${standard.description}`;
    });

    const footer = intl.formatMessage({
        description: 'Footer for lesson standards markdown',
        defaultMessage: `[**Learn more about CSTA's K-12 Computer Science Standards**](https://aka.ms/csta-standards)`,
    });

    return [header, ...standardStrings, '\n', footer].join('\n');
}

function getEducatorResources(educatorResources: EducatorResource[] | undefined): React.ReactNode {
    if (!educatorResources || educatorResources.length === 0) {
        return undefined;
    }

    // <p> tag is used here to add similar spacing to the other tabs.
    return (
        <p>
            <EducatorResources educatorResources={educatorResources} />
        </p>
    );
}
