import { IntlShape } from 'react-intl';

export function timeSince(time: number, intl: IntlShape) {
    const now = Date.now();

    const diff = (now - time) / 1000;
    if (isNaN(diff)) {
        throw new Error('invalid number');
    }

    if (diff <= 0) {
        return intl.formatMessage({
            description: 'time since text now',
            defaultMessage: 'now',
        });
    }
    if (diff < 10) {
        return intl.formatMessage({
            description: 'time since text (less than 10 seconds)',
            defaultMessage: 'a few seconds ago',
        });
    }

    const minute = 60;
    if (diff < minute) {
        return intl.formatMessage(
            {
                description: 'time since text (less than one minute)',
                defaultMessage: '{VALUE} seconds ago',
            },
            {
                VALUE: Math.floor(diff),
            }
        );
    }
    if (diff < 2 * minute) {
        return intl.formatMessage({
            description: 'time since text (less than two minutes)',
            defaultMessage: 'a minute ago',
        });
    }
    const hour = minute * 60;
    if (diff < hour) {
        return intl.formatMessage(
            {
                description: 'time since text (less than one hour)',
                defaultMessage: '{VALUE} minutes ago',
            },
            {
                VALUE: Math.floor(diff / minute),
            }
        );
    }
    if (diff < 2 * hour) {
        return intl.formatMessage({
            description: 'time since text (less than two hours)',
            defaultMessage: 'an hour ago',
        });
    }

    const day = hour * 24;
    if (diff < day) {
        return intl.formatMessage(
            {
                description: 'time since text (less than one day)',
                defaultMessage: '{VALUE} hours ago',
            },
            {
                VALUE: Math.floor(diff / hour),
            }
        );
    }
    if (diff < 2 * day) {
        return intl.formatMessage({
            description: 'time since text (less than two days)',
            defaultMessage: '1 day ago',
        });
    }

    const month = 30 * day;
    if (diff < month) {
        return intl.formatMessage(
            {
                description: 'time since text (less than one month)',
                defaultMessage: '{VALUE} days ago',
            },
            {
                VALUE: Math.floor(diff / day),
            }
        );
    }
    if (diff < 2 * month) {
        return intl.formatMessage({
            description: 'time since text (less than two months)',
            defaultMessage: '1 month ago',
        });
    }

    const year = day * 365;
    if (diff < year) {
        return intl.formatMessage(
            {
                description: 'time since text (less than one year)',
                defaultMessage: '{VALUE} months ago',
            },
            {
                VALUE: Math.floor(diff / month),
            }
        );
    }
    if (diff < 2 * year) {
        return intl.formatMessage({
            description: 'time since text (less than two years)',
            defaultMessage: '1 year ago',
        });
    }

    return intl.formatMessage(
        {
            description: 'time since text (more than one year)',
            defaultMessage: '{VALUE} years ago',
        },
        {
            VALUE: Math.floor(diff / year),
        }
    );
}
