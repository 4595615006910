import { mergeClasses } from '@fluentui/react-components';
import React, { useId } from 'react';
import PageNavigator from '../components/common/page-navigator';
import PageContainer from '../components/page-container';
import UnsafeMarkdownContent from '../components/unsafe-markdown-content';
import { useAppStyles, useMarkdownStyles } from '../styles';

// Document page properties.
export type DocsProps = {
    readonly src: string;
};

// Document page.
export default (props: DocsProps) => {
    const { src } = props;
    const styles = useAppStyles();
    const containerId = useId();
    useMarkdownStyles();

    return (
        <PageContainer className={styles.pageContainer}>
            <div className={mergeClasses(styles.pageContent, 'content-width', 'markdown-content')}>
                <div className={styles.flexRowSmallGap}>
                    <div id={containerId}>
                        <UnsafeMarkdownContent src={src} />
                    </div>
                    <PageNavigator containerId={containerId} />
                </div>
            </div>
        </PageContainer>
    );
};
