import React, { useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from './app';
import Authoring from './pages/authoring';
import CourseCatalog from './pages/course-catalog';
import CourseInfo from './pages/course-info';
import Docs from './pages/docs';
import Domains from './pages/domains';
import Home from './pages/home';
import HTMLPage from './pages/html';
import MyWork from './pages/my-work';
import WhatsNew from './pages/whats-new';

// Application routes.
export default () => {
    const location = useLocation();
    useLayoutEffect(() => window.scrollTo(0, 0), [location.pathname]);
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Home />} />
                <Route path="my-work">
                    <Route path="" element={<MyWork />} />
                    <Route path=":tabName" element={<MyWork />} />
                    <Route path="projects/new" element={<MyWork />} />
                </Route>
                <Route path="courses">
                    <Route path="" element={<CourseCatalog />} />
                    <Route path=":courseId" element={<CourseInfo />} />
                </Route>
                <Route path="release-notes" element={<Navigate to="/whats-new" />} />
                <Route path="whats-new">
                    <Route path="" element={<WhatsNew />} />
                    <Route path=":postId" element={<WhatsNew />} />
                </Route>
                <Route path="educators" element={<Docs src="/docs/educators.md" />} />
                <Route path="authoring" element={<Authoring />} />
                <Route path="faq" element={<Docs src="/docs/faq.md" />} />
                <Route path="domains" element={<Domains />} />
                <Route path="report" element={<Navigate to={`/${location.search}`} />} />
                <Route path="redirect" element={<Navigate to="/" state="signin-error" />} />
                <Route path="signin" element={<Navigate to="/" state="signin-error" />} />
                <Route
                    path="notice"
                    element={
                        <HTMLPage
                            src="/html/NOTICE.html"
                            title="Third Party Notices"
                            iframeStyle={{ backgroundColor: 'white' }}
                        />
                    }
                />
                {
                    /* If we fall through everything we have a share link failure, so render a toast for that */
                    <Route path="*" element={<Navigate to="/" state="sharelink-error" />} />
                }
            </Route>
        </Routes>
    );
};
