import { TableCellLayout, tokens } from '@fluentui/react-components';
import { CircleFilled } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PublishedProject } from '../../models';
import { useAppStyles } from '../../styles';
import { timeSince } from '../../utilities/time';
import { Indicator, IntlProps } from '../common';

// Last published cell properties.
export type LastPublishedCellProps = IntlProps<{
    state: PublishedProject['state'];
    lastPublished: Date;
    lastUpdatePublished?: boolean;
}>;

// Last Published data grid cell contents.
export default injectIntl((props: LastPublishedCellProps) => {
    const { intl, state, lastPublished, lastUpdatePublished } = props;
    const lastPublishedText = useMemo(() => timeSince(lastPublished.getTime(), intl), [lastPublished, intl]);
    const styles = useAppStyles();

    return (
        <TableCellLayout>
            {state === 'Publishing' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Message indicating a project is being published."
                        defaultMessage="Publishing..."
                    />
                </Indicator>
            )}
            {state === 'Unpublishing' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Message indicating a project is being unpublished."
                        defaultMessage="Unpublishing..."
                    />
                </Indicator>
            )}
            {state === 'Updating' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Message indicating a project is being updated."
                        defaultMessage="Updating..."
                    />
                </Indicator>
            )}
            {state === 'Created' && (
                <div className={styles.flexColumnSmallGap}>
                    {lastPublishedText}
                    {lastUpdatePublished !== true && (
                        <Indicator icon={<CircleFilled color={tokens.colorStatusWarningForeground1} />}>
                            <FormattedMessage
                                description="Text indicating unpublished changes on a project."
                                defaultMessage="Unpublished changes"
                            />
                        </Indicator>
                    )}
                </div>
            )}
        </TableCellLayout>
    );
});
