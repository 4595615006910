import { ButtonProps, DialogTrigger, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppStyles } from '../../styles';
import { TrackedButton } from '../common/tracked-controls';

// Dialog Cancel button with event tracking.
export default (props: ButtonProps) => {
    const styles = useAppStyles();
    return (
        <DialogTrigger action="close">
            <TrackedButton
                trackAction="cancel"
                appearance="secondary"
                className={mergeClasses(styles.button, styles.buttonMedium)}
                {...props}
            >
                <FormattedMessage description="Text on the dialog Cancel button" defaultMessage="Cancel" />
            </TrackedButton>
        </DialogTrigger>
    );
};
