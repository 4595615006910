import { makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TrackedLink } from '../common';

const useStyles = makeStyles({
    linkContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        left: '-200vw',
        top: '0',
        height: '60px',
        backgroundColor: tokens.colorNeutralBackground1,
        zIndex: 1,
        '&:focus-within': {
            left: '0',
        },
    },
});

export default () => {
    const styles = useStyles();
    return (
        <div className={styles.linkContainer}>
            <TrackedLink trackAction="skip-to-content" href="#main-content">
                <FormattedMessage
                    description="'Skip to main content' accessibility link"
                    defaultMessage="Skip to main content"
                />
            </TrackedLink>
        </div>
    );
};
