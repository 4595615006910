import * as React from 'react';
import { Body1, Link, makeStyles, tokens } from '@fluentui/react-components';
import { EducatorResource } from '@microsoft/vscodeedu-api';
import { IntlProps, TrackedLink } from '../common';
import { MimeTypeIcon } from '../../icons/mime-type';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: tokens.spacingHorizontalS,
        marginTop: tokens.spacingVerticalS,
    },
    educatorResourcesDescription: {
        display: 'inline-block',
    },
    educatorResourcesList: {
        display: 'flex',
        flexDirection: 'column',
    },
    educatorResourceIcon: {
        display: 'flex',
        alignItems: 'center',
        fontSize: tokens.fontSizeBase400,
    },
});

export type EducatorResourcesProps = IntlProps<{
    readonly educatorResources: EducatorResource[];
}>;

export default injectIntl((props: EducatorResourcesProps) => {
    const styles = useStyles();
    const { educatorResources, intl } = props;

    // loop through resources and create a list of links
    const resources = educatorResources.map((resource, index) => {
        return (
            <div key={index} className={styles.container}>
                <span className={styles.educatorResourceIcon}>
                    <MimeTypeIcon mimeType={resource.type} />
                </span>
                <TrackedLink
                    trackAction="open-educator-resource"
                    key={index}
                    href={resource.url}
                    download={resource.title}
                    target="_blank"
                >
                    {resource.title}
                </TrackedLink>
            </div>
        );
    });

    return (
        <div>
            <div className={styles.educatorResourcesDescription}>
                <Body1>
                    {intl.formatMessage({
                        description:
                            'A message indicating that the user must be a verified educator to access resources.',
                        defaultMessage: 'You must be a verified educator to access these resources.',
                    })}
                    &nbsp;
                    <Link href="https://aka.ms/vscedu/educator-resources">Learn more</Link>
                </Body1>
                <hr />
            </div>
            <div className={styles.educatorResourcesList}>{resources}</div>
        </div>
    );
});
