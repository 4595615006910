import { Skeleton, SkeletonItem, mergeClasses, tokens } from '@fluentui/react-components';
import React from 'react';
import { useAppStyles } from '../../styles';

// Course progress loading card.
export default (props: { showImage?: boolean }) => {
    const { showImage } = props;
    const styles = useAppStyles();
    const baseClass = mergeClasses(styles.card, styles.cardThreeColumns);

    const content = (
        <Skeleton appearance="translucent">
            {showImage === true && (
                <div className={styles.cardMedia}>
                    <SkeletonItem className={styles.cardMediaImage} size={128} />
                </div>
            )}
            <div className={mergeClasses(styles.cardContent, styles.flexGrow1)}>
                <SkeletonItem className={styles.cardCategory} size={12} />
                <SkeletonItem size={24} style={{ marginTop: tokens.spacingVerticalL }} />
                <SkeletonItem size={32} style={{ marginTop: tokens.spacingVerticalL }} />
                <SkeletonItem size={8} style={{ width: '40%', marginTop: tokens.spacingVerticalL }} />
            </div>
        </Skeleton>
    );

    return (
        <>
            <div className={baseClass}>{content}</div>
            <div className={mergeClasses(baseClass, styles.mobileHidden)}>{content}</div>
            <div className={mergeClasses(baseClass, styles.mobileHidden, styles.tabletHidden)}>{content}</div>
        </>
    );
};
