import { traceEvent } from '../utilities';
import { defineStyles } from './define-styles';

const mobileBreakpoint = 320;
const tabletBreakpoint = 768;
const computerBreakpoint = 992;
const largeMonitorBreakpoint = 1200;
const widescreenMonitorBreakpoint = 1920;

const largestMobilePortraitScreen = mobileBreakpoint + 100;
const largestMobileScreen = tabletBreakpoint - 1;
const largestTabletScreen = computerBreakpoint - 1;
const largestSmallMonitor = largeMonitorBreakpoint - 1;
const largestLargeMonitor = widescreenMonitorBreakpoint - 1;

export const PORTRAIT_MOBILE_AND_BELOW = createMediaQuery(undefined, largestMobilePortraitScreen);
export const MOBILE_AND_BELOW = createMediaQuery(undefined, largestMobileScreen);
export const TABLET_AND_BELOW = createMediaQuery(undefined, largestTabletScreen);
export const TABLET_ONLY = createMediaQuery(tabletBreakpoint, largestTabletScreen);
export const COMPUTER_AND_BELOW = createMediaQuery(undefined, largestSmallMonitor);
export const LARGE_MONITOR_AND_BELOW = createMediaQuery(undefined, largestLargeMonitor);

// Responsive layout styles.
export const responsiveStyles = defineStyles({
    mobilePortraitHidden: {
        [PORTRAIT_MOBILE_AND_BELOW]: {
            display: 'none !important',
        },
    },
    mobilePortraitOnly: {
        [createMediaQuery(largestMobilePortraitScreen, undefined)]: {
            display: 'none !important',
        },
    },
    mobileHidden: {
        [MOBILE_AND_BELOW]: {
            display: 'none !important',
        },
    },
    mobileOnly: {
        [createMediaQuery(largestMobileScreen, undefined)]: {
            display: 'none !important',
        },
    },
    tabletHidden: {
        [createMediaQuery(tabletBreakpoint, largestTabletScreen)]: {
            display: 'none !important',
        },
    },
    tabletOnly: {
        [MOBILE_AND_BELOW]: {
            display: 'none !important',
        },
        [createMediaQuery(computerBreakpoint, undefined)]: {
            display: 'none !important',
        },
    },
    computerHidden: {
        [createMediaQuery(computerBreakpoint, largestSmallMonitor)]: {
            display: 'none !important',
        },
    },
    computerOnly: {
        [TABLET_AND_BELOW]: {
            display: 'none !important',
        },
        [createMediaQuery(largeMonitorBreakpoint, undefined)]: {
            display: 'none !important',
        },
    },
    largeMonitorHidden: {
        [createMediaQuery(largeMonitorBreakpoint, largestLargeMonitor)]: {
            display: 'none !important',
        },
    },
    largeMonitorOnly: {
        [COMPUTER_AND_BELOW]: {
            display: 'none !important',
        },
        [createMediaQuery(widescreenMonitorBreakpoint, undefined)]: {
            display: 'none !important',
        },
    },
    widescreenHidden: {
        [createMediaQuery(widescreenMonitorBreakpoint, undefined)]: {
            display: 'none !important',
        },
    },
    widescreenOnly: {
        [LARGE_MONITOR_AND_BELOW]: {
            display: 'none !important',
        },
    },

    // Desktop is computer + large monitor + widescreen
    desktopHidden: {
        [createMediaQuery(computerBreakpoint, undefined)]: {
            display: 'none !important',
        },
    },
    desktopOnly: {
        [TABLET_AND_BELOW]: {
            display: 'none !important',
        },
    },

    // Portrait is the inverse of desktop
    portraitOnly: {
        [createMediaQuery(computerBreakpoint, undefined)]: {
            display: 'none !important',
        },
    },
    portraitHidden: {
        [TABLET_AND_BELOW]: {
            display: 'none !important',
        },
    },
});

function createMediaQuery(minWidth: number | undefined, maxWidth: number | undefined) {
    const parts: string[] = [`@media only screen`];
    if (minWidth !== undefined) {
        parts.push(`(min-width: ${minWidth}px)`);
    }
    if (maxWidth !== undefined) {
        parts.push(`(max-width: ${maxWidth}px)`);
    }
    return parts.join(' and ');
}

// Logs form factor and screen size to telemetry.
export function traceFormFactor() {
    const width = window.outerWidth;

    let formFactor: string;
    if (width < largestMobilePortraitScreen) {
        formFactor = 'mobile-portrait';
    } else if (width < largestMobileScreen) {
        formFactor = 'mobile';
    } else if (width < largestTabletScreen) {
        formFactor = 'tablet';
    } else if (width < largestSmallMonitor) {
        formFactor = 'small-monitor';
    } else if (width < largestLargeMonitor) {
        formFactor = 'large-monitor';
    } else {
        formFactor = 'extra-large-monitor';
    }

    traceEvent('screen.size', {
        width: width,
        height: window.outerHeight,
        formFactor,
    });
}
