import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    mergeClasses,
    Toast,
    ToastTitle,
    useToastController,
} from '@fluentui/react-components';
import Bowser from 'bowser';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppStyles } from '../../styles';
import { TrackContext, TrackedButton } from '../common';
import { CancelButton } from '../dialogs';
import CloseButton from '../dialogs/close-button';

// About dialog.
export default (props: Partial<DialogProps>) => {
    const styles = useAppStyles();
    const browser = Bowser.getParser(window.navigator.userAgent);
    const { dispatchToast } = useToastController('app-toaster');

    const content = `Application version: ${process.env.REACT_APP_VERSION ?? 'localhost'}
Web browser: ${browser.getBrowserName()} ${browser.getBrowserVersion()}
Operating system: ${browser.getOSName()} ${browser.getOSVersion()}
Platform: ${browser.getPlatformType()}`;

    const onCopy = useCallback(async () => {
        await navigator.clipboard.writeText(content);
        dispatchToast(
            <Toast appearance="inverted">
                <ToastTitle>Copied information to the clipboard.</ToastTitle>
            </Toast>,
            { intent: 'success' }
        );
    }, [content, dispatchToast]);

    return (
        <TrackContext.Provider value={{ container: 'about-dialog' }}>
            <Dialog {...props}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<CloseButton />}>
                            <FormattedMessage description="About dialog title." defaultMessage="About" />
                        </DialogTitle>
                        <DialogContent className={styles.flexRowCentered}>
                            <div className={styles.dialogContent}>
                                <h3>
                                    <FormattedMessage
                                        description="Text on the About dialog."
                                        defaultMessage="Visual Studio Code for Education"
                                    />
                                </h3>
                                {content.split('\n').map((o) => (
                                    <div key={o}>{o}</div>
                                ))}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <TrackedButton
                                trackAction="copy"
                                appearance="primary"
                                className={mergeClasses(styles.button, styles.buttonMedium)}
                                onClick={onCopy}
                            >
                                <FormattedMessage
                                    description="About dialog copy button."
                                    defaultMessage="Copy to clipboard"
                                />
                            </TrackedButton>
                            <CancelButton />
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
};
