import React from 'react';
import { IconProps } from './icon-props';

export const TwitterLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" {...props}>
        <path
            fill="currentColor"
            d="M6.29 16.2499C7.81204 16.2658 9.3223 15.9816 10.7345 15.4138C12.1468 14.8459 13.4333 14.0054 14.5207 12.9403C15.6081 11.8752 16.475 10.6064 17.072 9.20619C17.669 7.80602 17.9843 6.30197 18 4.77993V4.04993C18.7874 3.46378 19.4645 2.74261 20 1.91993C19.2495 2.25122 18.4543 2.47023 17.64 2.56993C18.4964 2.05518 19.1389 1.24941 19.45 0.299931C18.6437 0.780351 17.7609 1.11861 16.84 1.29993C16.221 0.639482 15.4013 0.201849 14.5081 0.054966C13.6149 -0.0919166 12.6983 0.0601681 11.9004 0.487611C11.1025 0.915055 10.468 1.59395 10.0955 2.4189C9.7229 3.24385 9.6331 4.16872 9.84 5.04993C8.20944 4.96253 6.61523 4.53479 5.15992 3.79422C3.70461 3.05365 2.42043 2.01665 1.39 0.74993C0.865387 1.65365 0.7047 2.72326 0.940617 3.74124C1.17653 4.75921 1.79134 5.6491 2.66 6.22993C2.0072 6.20873 1.36915 6.03035 0.8 5.70993C0.806284 6.65291 1.13665 7.56505 1.73567 8.29335C2.3347 9.02165 3.16595 9.52181 4.09 9.70993C3.48658 9.87296 2.85402 9.89689 2.24 9.77993C2.5035 10.5938 3.01382 11.3055 3.70014 11.8162C4.38645 12.3269 5.21472 12.6113 6.07 12.6299C4.63388 13.794 2.84842 14.442 1 14.4699C0.665735 14.471 0.331735 14.451 0 14.4099C1.87834 15.6105 4.06078 16.2489 6.29 16.2499Z"
        />
    </svg>
);

export const YouTubeLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16" {...props}>
        <path
            fill="currentColor"
            d="M19.7999 3.73556C19.7074 2.97612 19.435 2.24972 19.0054 1.61666C18.751 1.33705 18.4419 1.1127 18.0972 0.957518C17.7525 0.802338 17.3796 0.719641 17.0016 0.714559C14.2026 0.497559 10.0044 0.497559 10.0044 0.497559H9.995C9.995 0.497559 5.79688 0.497559 2.99841 0.714559C2.62044 0.719682 2.24755 0.802397 1.90286 0.957574C1.55818 1.11275 1.24904 1.33708 0.99463 1.61666C0.565311 2.24986 0.292945 2.97619 0.20007 3.73556C0.0754804 4.88223 0.00870597 6.03444 0 7.18783V8.80583C0.00867095 9.95942 0.0754455 11.1118 0.20007 12.2587C0.292568 13.0176 0.564979 13.7433 0.99463 14.3756C1.58854 14.9466 2.37604 15.2723 3.19971 15.2878C4.79956 15.4516 10 15.5023 10 15.5023C10 15.5023 14.2026 15.4948 17.0016 15.2804C17.3796 15.2743 17.7524 15.191 18.0969 15.0355C18.4415 14.88 18.7507 14.6557 19.0054 14.3763C19.4347 13.7439 19.7071 13.0182 19.7999 12.2595C19.9245 11.1125 19.9913 9.96003 20 8.80636V7.18844C19.9913 6.03485 19.9245 4.88243 19.7999 3.73556ZM7.50232 11.7512V4.24874L13.7543 7.99996L7.50232 11.7512Z"
        />
    </svg>
);

export const TikTokLogo = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" {...props}>
        <path
            fill="currentColor"
            d="M12.1659 6.10811C13.5699 7.1109 15.2892 7.70097 17.1463 7.70097V4.88932C16.1097 4.66863 15.192 4.12721 14.502 3.37412C13.321 2.63752 12.4701 1.42232 12.2206 0H9.61712V14.2669C9.61122 15.9301 8.26075 17.2768 6.59537 17.2768C5.61398 17.2768 4.74212 16.8093 4.18995 16.085C3.20394 15.5876 2.52777 14.5664 2.52777 13.3872C2.52777 11.7193 3.88048 10.3671 5.54945 10.3671C5.86921 10.3671 6.17742 10.4168 6.46652 10.5085V7.66613C2.88246 7.74014 0 10.6671 0 14.2669C0 16.0639 0.717798 17.693 1.88279 18.8833C2.93379 19.5886 4.19898 20 5.56019 20C9.20842 20 12.1659 17.044 12.1659 13.3975V6.10811H12.1659Z"
        />
    </svg>
);
