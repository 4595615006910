import React, { HTMLProps } from 'react';

// Project type label properties.
export type ProjectTypeLabelProps = Readonly<
    HTMLProps<HTMLDivElement> & {
        type: string;
    }
>;

// Project type label.
export default (props: ProjectTypeLabelProps) => {
    const { type } = props;
    switch (type?.toLowerCase()) {
        case 'html':
        case 'html5':
        case 'web':
            return <div {...props}>HTML, CSS, JavaScript</div>;
        case 'jsx':
        case 'react':
        case 'reactjs':
            return <div {...props}>ReactJS</div>;
        case 'p5':
        case 'p5js':
            return <div {...props}>P5.JS</div>;
        case 'py':
        case 'python':
            return <div {...props}>Python</div>;
        default:
            return <></>;
    }
};
