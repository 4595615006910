import { mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import IdentityGroup from './identity-group';
import LogoHomeLink from './logo-home-link';
import NavigationGroup from './navigation-group';
import SkipToContentLink from './skip-to-content-link';

// Top header control.
export default () => {
    const appStyles = useAppStyles();
    return (
        <TrackContext.Provider value={{ container: 'header' }}>
            <SkipToContentLink />
            <div className={mergeClasses(appStyles.desktopOnly, appStyles.header, 'content-width')}>
                <LogoHomeLink />
                <NavigationGroup />
                <div className={appStyles.flexGrow1}></div>
                <IdentityGroup />
            </div>
            <div className={mergeClasses(appStyles.desktopHidden, appStyles.header)}>
                <NavigationGroup />
                <LogoHomeLink />
                <IdentityGroup />
            </div>
        </TrackContext.Provider>
    );
};
