// Property constraints.
export const MaxTitleLength = 100;
export const MaxDescriptionLength = 500;

// Well-known URLs.
export const KnownLink = {
    termsOfUse: 'https://www.microsoft.com/legal/terms-of-use',
    privacyPolicy: 'https://privacy.microsoft.com/privacystatement',
    trademarks: 'https://www.microsoft.com/legal/intellectualproperty/trademarks',
    careers: 'https://careers.microsoft.com',
    microsoftHome: 'https://www.microsoft.com',
    makeCode: 'https://www.microsoft.com/makecode',
    vscode: 'https://code.visualstudio.com',
    accessibility: 'https://www.microsoft.com/accessibility',
    vsceTwitter: 'https://twitter.com/VSCodeEDU',
    vsceYouTube: 'https://www.youtube.com/@vscode-edu',
    vsceLinkedIn: 'https://www.linkedin.com/groups/14470085/',
};
