import { mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SignInContext } from '../../app';
import { SignInImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackedButton } from './tracked-controls';

// No signed in user state.
export default () => {
    const styles = useAppStyles();
    return (
        <div className={styles.emptyState}>
            <SignInImage className={styles.emptyStateImage} />
            <FormattedMessage
                description="No signed in user message"
                defaultMessage="Please sign in with your account to view and manage your course progress and projects."
            />
            <SignInContext.Consumer>
                {({ ensureSignedIn }) => (
                    <TrackedButton
                        trackAction="empty-state-sign-in"
                        className={mergeClasses(styles.button, styles.buttonPrimary)}
                        onClick={() => ensureSignedIn(window.location.href)}
                    >
                        <FormattedMessage description="Sign-in button text" defaultMessage="Sign in now" />
                    </TrackedButton>
                )}
            </SignInContext.Consumer>
        </div>
    );
};
