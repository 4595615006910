import { makeStaticStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import PageContainer from '../components/page-container';
import { useAppStyles } from '../styles';
import { getFetchUrl } from '../utilities';

const useStaticStyles = makeStaticStyles({
    // Styling to make the HTML page take full vertical spacing of the pageContent area.
    '.html-iframe': {
        flexGrow: 1,
    },
});

// HTML page properties.
export type HTMLProps = {
    // Source for the HTML page to display.
    readonly src: string;
    // Title for the HTML iframe.
    readonly title: string;
    // Optional style overrides for the iframe.
    readonly iframeStyle?: React.CSSProperties;
};

// Host a static HTML page inside the main content area.
export default (props: HTMLProps) => {
    const { src, iframeStyle, title } = props;
    const styles = useAppStyles();
    useStaticStyles();

    return (
        <PageContainer className={mergeClasses(styles.pageContainer, 'full-page-container')}>
            <div className={mergeClasses(styles.pageContent, 'content-width')}>
                <iframe title={title} className="html-iframe" style={iframeStyle} src={getFetchUrl(src)} />
            </div>
        </PageContainer>
    );
};
