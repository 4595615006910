import {
    ApplicationInsights,
    DistributedTracingModes,
    IConfig,
    IConfiguration,
    ICustomProperties,
    ITelemetryPlugin,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { isNonEmptyString } from '@microsoft/vscodeedu-api';

// Set of keywords to look in parameter names to exclude from logging.
const blockedParamKeywords = ['username', 'password', 'token', 'pwd', 'code'];

// Singleton instance of App Insights.
let instance: ApplicationInsights;

// Creates debug console logger plugin.
function createConsoleLogger(): ITelemetryPlugin {
    return {
        initialize: () => {},
        identifier: 'consoleLogger',
        priority: 1,
        /** ITelemetryPlugin comment says this is actually required,
         * but I'm not seeing any examples of it
         **/
        // setNextPlugin: (next) => {

        // },
        processTelemetry: (item, _context) => {
            switch (item.baseType) {
                case 'MessageData':
                    console.info(item.baseData?.message);
                    break;
                case 'EventData':
                case 'MetricData':
                    console.info(item.baseData?.name, item.data);
                    break;
                case 'RemoteDependencyData':
                    console.info(item.baseData?.type, item.baseData?.target, item.baseData?.responseCode);
                    break;
                case 'PageviewData':
                case 'PageviewPerformanceData':
                    break;
                default:
                    console.info(item);
                    break;
            }
            _context?.processNext(item);
        },
    };
}

// Initializes Application Insights telemetry infrastructure.
export const initializeAppInsights = () => {
    const isProd = window.location.hostname === 'vscodeedu.com';
    const debug = new URLSearchParams(window.location.search).get('feature.debug') === 'true';
    const instrumentationKey = isProd ? 'a5536583-4e7c-40cb-b63e-c199cefa9d63' : 'd3f3c597-3809-4a3a-80c2-392f7f7b580a';

    // Build configuration from the app's config.
    const appInsightsConfig: IConfiguration & IConfig = {
        connectionString: `InstrumentationKey=${instrumentationKey};IngestionEndpoint=https://westus3-1.in.applicationinsights.azure.com/`,
        disableExceptionTracking: false,
        disableAjaxTracking: isProd && !debug,
        disableFetchTracking: true,
        disableCookiesUsage: true,
        disablePageUnloadEvents: ['unload', 'beforeunload'],
        distributedTracingMode: DistributedTracingModes.W3C,
        emitLineDelimitedJson: false,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableDebug: !isProd || debug,
        isBeaconApiDisabled: true,
        loggingLevelConsole: isProd && !debug ? 0 : 2,
        loggingLevelTelemetry: 2,
        extensions: debug ? [createConsoleLogger()] : [],
    };

    // Create singleton instance of ApplicationInsights.
    instance = new ApplicationInsights({ config: appInsightsConfig });
    instance.addTelemetryInitializer((item) => {
        item.tags ??= [];
        item.tags['ai.application.ver'] = process.env.REACT_APP_VERSION ?? 'localhost';
        if (item.baseType === 'PageviewData') {
            item.baseData ??= {};
            item.baseData.properties ??= {};
            item.baseData.properties['urlReferrer'] = stripSensitiveParams(document.referrer);
            item.baseData.refUri = stripSensitiveParams(item.baseData.refUri);
            item.baseData.uri = stripSensitiveParams(item.baseData.uri);
        }
    });

    // Initialize telemetry flow.
    instance.loadAppInsights();
};

// Log a trace diagnostic message
export const trackTrace = (message: string, severityLevel: SeverityLevel) =>
    instance?.trackTrace({ severityLevel, message });

// Log a verbose diagnostic message.
export const trackVerbose = (message: string, properties?: ICustomProperties) =>
    instance?.trackTrace({ severityLevel: SeverityLevel.Verbose, message, properties });

// Log an informational diagnostic message
export const trackInfo = (message: string, properties?: ICustomProperties) =>
    instance?.trackTrace({ severityLevel: SeverityLevel.Information, message, properties });

// Log a warning diagnostic message
export const trackWarning = (message: string, properties?: ICustomProperties) =>
    instance?.trackTrace({ severityLevel: SeverityLevel.Warning, message, properties });

// Log a user action or other occurrence.
export const traceEvent = (name: string, customDimensions?: ICustomProperties) =>
    instance?.trackEvent({ name }, customDimensions);

// Log a numeric value that is not associated with a specific event.
export const trackMetric = (name: string, average: number) => instance?.trackMetric({ name, average });

// Log an exception that you have caught.
export const trackError = (error: unknown, message?: string) =>
    instance?.trackException({ error: error as Error }, isNonEmptyString(message) ? { message } : undefined);

// Strip out potentially problematic keys from URLs when logging telemetry
function stripSensitiveParams(href: string) {
    // Mirrored from https://github.com/microsoft/pxt/blob/master/docfiles/apptracking.html#L34
    if (!href) {
        return href;
    }

    const url = new URL(href);

    // Just as a precaution, strip any suspicious query parameters.
    for (const param of url.searchParams.keys()) {
        if (containsSensitiveTerm(param)) {
            url.searchParams.delete(param);
        }
    }

    // Clear hash as that as client only data
    url.hash = '';

    return url.toString();
}

function containsSensitiveTerm(input: string) {
    const inputLower = input.toLocaleLowerCase();
    return blockedParamKeywords.some((k) => inputLower.indexOf(k) !== -1);
}
