import { ButtonProps, DialogTrigger } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../common';
import { TrackedButton } from '../common/tracked-controls';

// Dialog close button with event tracking.
export default injectIntl((props: IntlProps<ButtonProps>) => {
    return (
        <DialogTrigger action="close">
            <TrackedButton
                {...props}
                trackAction="close"
                appearance="subtle"
                icon={<DismissRegular />}
                aria-label={props.intl.formatMessage({
                    description: 'Dialog close button aria label',
                    defaultMessage: 'Close',
                })}
            />
        </DialogTrigger>
    );
});
