import { VSCodeUrl, assert, compareLocale, models } from '@microsoft/vscodeedu-api';
import { ProfileTheme, getProfileFileName } from '@microsoft/vscodeedu-common';
import { Browser } from '@microsoft/vscodeedu-zip-util/dist/browser';
import { produce } from 'immer';
import React, { Dispatch, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { ContextProps, StoreState, UserContext, apiEnvironment, getUserClient, getUserId, replaceTokens } from '.';
import { Project, ProjectTemplate, PublishedProject } from '../models';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';
import { downloadBlob } from '../utilities/download';
import { useTheme } from './theme-context';

// User projects store.
export type ProjectStore = Readonly<{
    state: StoreState;
    items: Project[];
}>;

// User project store action.
export type ProjectAction =
    | { type: 'Load' }
    | { type: 'Reload' }
    | { type: 'LoadResult'; items: Project[] | undefined }
    | { type: 'LoadError' }
    | { type: 'Create'; template: ProjectTemplate; project: Partial<Project> }
    | { type: 'CreateError'; projectId: string }
    | { type: 'Update'; project: Project }
    | { type: 'UpdateResult'; project: Project }
    | { type: 'UpdateError'; projectId: string; previous?: Project }
    | { type: 'Publish'; projectId: string }
    | { type: 'Unpublish'; projectId: string }
    | { type: 'Delete'; projectId: string }
    | { type: 'DeleteResult'; projectId: string }
    | { type: 'Download'; projectId: string }
    | { type: 'CreateSharedLink'; projectId: string }
    | { type: 'DeleteSharedLink'; projectId: string };

// User project context.
export const ProjectContext = createContext<[state: ProjectStore, reducer: Dispatch<ProjectAction>]>(undefined!);

// User project context provider.
export const ProjectContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const [userStore] = useContext(UserContext);
    const client = getUserClient(userStore);
    const userId = getUserId(userStore);
    const [{ profileTheme }] = useTheme();
    const runner = useAsyncRunner();

    // Respond to user change by reloading the data.
    useEffect(() => dispatch({ type: 'Reload' }), []);

    // Converts data model into view model.
    const toModel = useCallback((data: Partial<models.Project>) => {
        return {
            ...data,
            state: data.id !== undefined ? 'Created' : 'Creating',
        } as Project;
    }, []);

    // List available user projects.
    const listProjects = useCallback(
        async () =>
            runner.run({
                task: async () => {
                    if (client) {
                        const data = await client.listUserProjects('me');
                        dispatch({ type: 'LoadResult', items: data.map(toModel) });
                    }
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading projects.',
                }),
            }),
        [runner, intl, client, toModel]
    );

    // Creates a new project, then updates the store.
    const createProject = useCallback(
        async (template: ProjectTemplate, project: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    // Create project metadata.
                    const data = await client!.createOrUpdateUserProject('me', project.projectId, { body: project });

                    // Replaces project name/description in template files.
                    const files = await replaceTokens(client!, template, project);

                    // Pack project files into a ZIP archive.
                    const zip = new Browser.ZipBuilder();
                    const encoder = new TextEncoder();
                    for (const file of Object.keys(files)) {
                        zip.withFile({
                            name: file,
                            input: encoder.encode(files[file]),
                        });
                    }

                    // Publish ZIP archive to project's drive.
                    const zipBlob = await zip.create();
                    await client!.uploadUserProjectDrive('me', project.projectId, { data: zipBlob });

                    // Open project in VS Code.
                    const model = toModel(data);
                    window.location.href = getOpenProjectUrl(projectId, profileTheme, userId).toString();

                    dispatch({ type: 'UpdateResult', project: model });
                },
                onError: () => {
                    dispatch({ type: 'CreateError', projectId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Creating project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully created project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while creating project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [client, intl, profileTheme, runner, toModel, userId]
    );

    // Updates an existing project, then updates the store.
    const updateProject = useCallback(
        async (project: Project, previous?: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    const data = await client!.createOrUpdateUserProject('me', projectId, { body: project });
                    dispatch({ type: 'UpdateResult', project: toModel(data) });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', projectId, previous });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Updating project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while updating project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [client, intl, runner, toModel]
    );

    // Publishes a project, then updates the store.
    const publishProject = useCallback(
        async (project: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    const data = await client!.publishUserProject('me', projectId);
                    dispatch({ type: 'UpdateResult', project: toModel(data) });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', projectId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Publishing project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully published project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while publishing project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [client, intl, runner, toModel]
    );

    // Unpublishes a project, then updates the store.
    const unpublishProject = useCallback(
        async (project: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    const data = await client!.unpublishUserProject('me', projectId);
                    dispatch({ type: 'UpdateResult', project: toModel(data) });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', projectId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Unpublishing project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully unpublished project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while unpublishing project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [client, intl, runner, toModel]
    );

    // Deletes an existing project, then updates the store.
    const deleteProject = useCallback(
        async (project: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    await client!.deleteUserProject('me', projectId);
                    dispatch({ type: 'DeleteResult', projectId });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', projectId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Deleting project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully deleted project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while deleting project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [client, intl, runner]
    );

    // Downloads project's contents as a ZIP archive.
    const downloadProject = useCallback(
        async (project: Project) => {
            const { title } = project;
            runner.run({
                task: async () => {
                    const zip = await client!.downloadUserProjectDrive('me', project.projectId);
                    if (zip.blobBody) {
                        downloadBlob(await zip.blobBody, `${project.title}.zip`);
                    }
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Downloading project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while downloading project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [client, intl, runner]
    );

    // Creates shared link for a project, then updates the store.
    const createSharedLink = useCallback(
        async (project: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    const data = await client!.createUserProjectSharedLink('me', projectId);
                    dispatch({ type: 'UpdateResult', project: toModel(data) });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', projectId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Creating shared link for project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while creating shared link for project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [runner, intl, client, toModel]
    );

    // Deletes shared link for a project, then updates the store.
    const deleteSharedLink = useCallback(
        async (project: Project) => {
            const { projectId, title } = project;
            runner.run({
                task: async () => {
                    await client!.deleteUserProjectSharedLink('me', projectId);
                    const data = await client!.getUserProject('me', projectId);
                    dispatch({ type: 'UpdateResult', project: toModel(data) });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', projectId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Deleting shared link for project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while deleting shared link for project {PROJECT_TITLE}.',
                    },
                    { PROJECT_TITLE: title }
                ),
            });
        },
        [runner, intl, client, toModel]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: ProjectStore, action: ProjectAction) =>
            produce(store, (draft) => {
                switch (action.type) {
                    case 'Create':
                        traceEvent('project-context.action', {
                            type: action.type,
                            state: draft.state,
                            projectType: action.template.type,
                        });
                        break;
                    default:
                        traceEvent('project-context.action', { type: action.type, state: draft.state });
                        break;
                }

                switch (action.type) {
                    case 'Load':
                        if (draft.state === 'NotLoaded') {
                            listProjects();
                            draft.state = 'Loading';
                        }
                        break;
                    case 'Reload':
                        if (draft.state !== 'Loading' && draft.state !== 'NotLoaded') {
                            listProjects();
                            draft.state = 'Loading';
                        }
                        break;
                    case 'LoadResult':
                        if (action.items !== undefined) {
                            draft.state = 'Loaded';
                            draft.items = action.items;
                        } else {
                            draft.state = 'NotLoaded';
                            draft.items = [];
                        }
                        break;
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    case 'Create': {
                        const item = toModel({
                            ...action.project,
                            projectId: crypto.randomUUID(),
                            type: action.template.type,
                        });

                        createProject(action.template, item);
                        draft.items.push(item);
                        break;
                    }
                    case 'CreateError': {
                        const index = draft.items.findIndex((o) => o.projectId === action.projectId);
                        if (index >= 0) {
                            draft.items.splice(index, 1);
                        }
                        break;
                    }
                    case 'Update': {
                        const item = draft.items.find((o) => o.projectId === action.project.projectId);
                        if (item) {
                            updateProject(action.project, item);
                            item.state = 'Updating';
                        }
                        break;
                    }
                    case 'UpdateError': {
                        const item = draft.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            item.state = 'Created';
                        }
                        break;
                    }
                    case 'Publish': {
                        const item = draft.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            item.state = 'Publishing';
                            publishProject(item);
                        }
                        break;
                    }
                    case 'Unpublish': {
                        const item = draft.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            item.state = 'Unpublishing';
                            unpublishProject(item);
                        }
                        break;
                    }
                    case 'UpdateResult': {
                        const index = draft.items.findIndex((o) => o.projectId === action.project.projectId);
                        if (index >= 0) {
                            draft.items[index] = { ...action.project, state: 'Created' };
                        }
                        break;
                    }
                    case 'Delete': {
                        const item = draft.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            deleteProject(item);
                            item.state = 'Deleting';
                        }
                        break;
                    }
                    case 'DeleteResult': {
                        const index = draft.items.findIndex((o) => o.projectId === action.projectId);
                        if (index >= 0) {
                            draft.items.splice(index, 1);
                        }
                        break;
                    }
                    case 'Download': {
                        const item = store.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            downloadProject(item);
                        }
                        break;
                    }
                    case 'CreateSharedLink': {
                        const item = draft.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            createSharedLink(item);
                            item.state = 'Updating';
                        }
                        break;
                    }
                    case 'DeleteSharedLink': {
                        const item = draft.items.find((o) => o.projectId === action.projectId);
                        if (item) {
                            deleteSharedLink(item);
                            item.state = 'Updating';
                        }
                        break;
                    }
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [
            listProjects,
            toModel,
            createProject,
            updateProject,
            publishProject,
            unpublishProject,
            deleteProject,
            downloadProject,
            createSharedLink,
            deleteSharedLink,
        ]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'NotLoaded', items: [] });
    return <ProjectContext.Provider value={[state, dispatch]}>{props.children}</ProjectContext.Provider>;
});

// Use user projects context.
export const useProjects = () => {
    const [store, dispatch] = useContext(ProjectContext);
    useEffect(() => dispatch({ type: 'Load' }), [dispatch]);
    return [store, dispatch] as [typeof store, typeof dispatch];
};

// Selector to get a project by ID.
export const getProject = createSelector(
    (store: ProjectStore) => store.items,
    (_: unknown, projectId: string) => projectId,
    (items, projectId) => items.find((o) => o.projectId === projectId)
);

// Selector to get whether project store is currently loading.
export const getIsLoadingProjects = createSelector(
    (store: ProjectStore) => store.state,
    (state) => state !== 'Loaded'
);

// Selector to get sorted list of projects.
export const getSortedProjects = createSelector(
    (store: ProjectStore) => store.items,
    (items) => [...items].sort((a, b) => compareLocale(a.title, b.title))
);

// Selector to get sorted list of published projects.
export const getPublishedProjects = createSelector(
    (store: ProjectStore) => store.items,
    (items) => items.filter((o) => o.publicUrl).sort((a, b) => compareLocale(a.title, b.title)) as PublishedProject[]
);

// Selector to get a unique new project name.
export const getNewProjectName = createSelector(
    (store: ProjectStore) => store.items,
    (items) => {
        for (let i = 1; i <= items.length + 1; i++) {
            const title = `Project ${i}`;
            if (!items.some((o) => !o.title.localeCompare(title))) {
                return title;
            }
        }
        return 'Project';
    }
);

// Returns a URL to open a project in VS Code.
export const getOpenProjectUrl = (projectId: string, theme: ProfileTheme, userId?: string) => {
    return VSCodeUrl.forProject(apiEnvironment, projectId, {
        accountId: userId,
        profile: getProfileFileName('project', theme),
    });
};

// Returns a URL to open a project in VS Code.
export const useOpenProjectUrl = (projectId: string) => {
    const [{ profileTheme }] = useTheme();
    const [userStore] = useContext(UserContext);
    const userId = getUserId(userStore);
    return getOpenProjectUrl(projectId, profileTheme, userId);
};
