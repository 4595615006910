import React, { HTMLProps, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '.';
import { timeSince } from '../../utilities';

// Time ago label properties.
export type TimeAgoLabelProps = IntlProps<
    HTMLProps<HTMLDivElement> & {
        date: Date;
    }
>;

// Time ago label.
export default injectIntl((props: TimeAgoLabelProps) => {
    const { date, intl } = props;
    const timeLabel = useMemo(() => timeSince(date.getTime(), intl), [intl, date]);
    return <div {...props}>{timeLabel}</div>;
});
