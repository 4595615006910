import React, { PropsWithChildren } from 'react';

// Page container properties.
export type PageContainerProps = PropsWithChildren<{
    readonly className?: string;
}>;

// Page container.
export default (props: PageContainerProps) => {
    const { children, className } = props;
    return (
        <div className={className} role="main" id="main-content">
            {children}
        </div>
    );
};
