import {
    Button,
    Toast,
    ToastTitle,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
    useToastController,
} from '@fluentui/react-components';
import React, { AnchorHTMLAttributes, useCallback, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useConfig } from '../../data';
import {
    FeedbackExcitedImage,
    FeedbackFrustratedImage,
    FeedbackHappyImage,
    FeedbackOkImage,
    FeedbackSadImage,
} from '../../icons/feedback';
import { useAppStyles } from '../../styles';
import { IntlProps } from './intl-props';

const useStyles = makeStyles({
    button: {
        minWidth: 'unset',
        minHeight: 'unset',
        ...shorthands.padding(tokens.spacingHorizontalXS),
        '& > img': {
            width: tokens.spacingHorizontalXXXL,
            height: tokens.spacingHorizontalXXXL,
        },
    },
});

// Feedback icons group properties.
export type FeedbackGroupProps = IntlProps<{
    qid2: string;
    qid3: string;
    qid4: string;
}>;

// Feedback icons group.
export default injectIntl((props: FeedbackGroupProps) => {
    const { intl, qid2, qid3, qid4 } = props;
    const id = `${qid2}-${qid3}-${qid4}`;

    const appStyles = useAppStyles();
    const styles = useStyles();

    // Generate feedback URLs per emoji.
    const { silentFeedbackBaseUrl } = useConfig();
    const urls = useMemo(() => {
        return [1, 2, 3, 4, 5].map((i) => {
            const url = new URL(silentFeedbackBaseUrl);
            url.searchParams.set(
                'Q_PopulateResponse',
                `{"QID1":"${i}","QID2":"${qid2}","QID3":"${qid3}","QID4":"${qid4}"}`
            );
            return url.toString();
        });
    }, [silentFeedbackBaseUrl, qid2, qid3, qid4]);

    // Track feedback completion state.
    const [completed, setCompleted] = React.useState([] as string[]);
    const { dispatchToast } = useToastController('app-toaster');

    // Record feedback completion and show a thank you message.
    const onClick = useCallback(() => {
        setCompleted([...completed, id]);
        dispatchToast(
            <Toast appearance="inverted">
                <ToastTitle>
                    <FormattedMessage
                        description="Title of feedback icon group"
                        defaultMessage="Thank you for your feedback!"
                    />
                </ToastTitle>
            </Toast>,
            { intent: 'success', toastId: 'progress' }
        );
    }, [completed, dispatchToast, id]);

    // Do not show feedback icons if already completed.
    if (completed.includes(id)) {
        return <></>;
    }

    const SmileyButton = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
        return (
            <Button
                className={styles.button}
                as="a"
                appearance="subtle"
                size="small"
                shape="circular"
                target="_blank"
                onClick={onClick}
                {...props}
            >
                {props.children}
            </Button>
        );
    };

    return (
        <div className={mergeClasses(appStyles.flexColumnSmallGap, appStyles.alignCenter)}>
            <FormattedMessage description="Title of feedback icon group" defaultMessage="Tell us what you think" />
            <div className={appStyles.flexRow}>
                <SmileyButton
                    href={urls[0]}
                    title={intl.formatMessage({
                        description: 'Feedback icon tooltip',
                        defaultMessage: 'Frustrated',
                    })}
                >
                    <FeedbackFrustratedImage />
                </SmileyButton>
                <SmileyButton
                    href={urls[1]}
                    title={intl.formatMessage({
                        description: 'Feedback icon tooltip',
                        defaultMessage: 'Sad',
                    })}
                >
                    <FeedbackSadImage />
                </SmileyButton>
                <SmileyButton
                    href={urls[2]}
                    title={intl.formatMessage({
                        description: 'Feedback icon tooltip',
                        defaultMessage: 'OK',
                    })}
                >
                    <FeedbackOkImage />
                </SmileyButton>
                <SmileyButton
                    href={urls[3]}
                    title={intl.formatMessage({
                        description: 'Feedback icon tooltip',
                        defaultMessage: 'Happy',
                    })}
                >
                    <FeedbackHappyImage />
                </SmileyButton>
                <SmileyButton
                    href={urls[4]}
                    title={intl.formatMessage({
                        description: 'Feedback icon tooltip',
                        defaultMessage: 'Excited',
                    })}
                >
                    <FeedbackExcitedImage />
                </SmileyButton>
            </div>
        </div>
    );
});
