import { compareLocale } from '@microsoft/vscodeedu-api';
import { useMemo } from 'react';
import { DomainInfo } from '../models/domain';
import { useConfig } from './config-context';

// Returns list of domains used by VS Code for Education services.
export const useDomains = () => {
    const { cleverAuth, curriculumAuthoring } = useConfig();
    return useMemo(() => {
        // All domains below must be listed in the connect-src csp override in config-overrides.js
        const value: DomainInfo[] = [
            {
                category: 'required',
                displayName: 'auth.vscode.dev',
                pingUrl: 'https://auth.vscode.dev',
            },
            {
                category: 'required',
                displayName: 'az764295.vo.msecnd.net',
                pingUrl: 'https://az764295.vo.msecnd.net',
            },
            {
                category: 'required',
                displayName: 'default.exp-tas.com',
                pingUrl: 'https://default.exp-tas.com',
            },
            {
                category: 'required',
                displayName: 'marketplace.visualstudio.com',
                pingUrl: 'https://marketplace.visualstudio.com',
            },
            {
                category: 'required',
                displayName: '*.vscode-cdn.net',
                pingUrl: 'https://www.vscode-cdn.net',
            },
            {
                category: 'required',
                displayName: '*.vscode-unpkg.net',
                pingUrl: 'https://www.vscode-unpkg.net',
            },
            {
                category: 'required',
                displayName: 'vscode.dev',
                pingUrl: 'https://vscode.dev',
            },
            {
                category: 'required',
                displayName: 'vscodeedu.com',
                pingUrl: 'https://vscodeedu.com',
            },
            {
                category: 'auth',
                displayName: 'aadcdn.msftauth.net',
                pingUrl: 'https://aadcdn.msftauth.net',
            },
            {
                category: 'auth',
                displayName: 'login.live.com',
                pingUrl: 'https://login.live.com',
            },
            {
                category: 'auth',
                displayName: 'login.microsoftonline.com',
                pingUrl: 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration',
            },
            {
                category: 'telemetry',
                displayName: 'browser.events.data.msn.com',
                pingUrl: 'https://browser.events.data.msn.com',
            },
            {
                category: 'telemetry',
                displayName: 'mobile.events.data.microsoft.com',
                pingUrl: 'https://mobile.events.data.microsoft.com',
            },
            {
                category: 'telemetry',
                displayName: 'js.monitor.azure.com',
                pingUrl: 'https://js.monitor.azure.com',
            },
            {
                category: 'settings',
                displayName: '*.gallery.vsassets.io',
                pingUrl: 'https://www.gallery.vsassets.io',
            },
            {
                category: 'settings',
                displayName: '*.gallerycdn.vsassets.io',
                pingUrl: 'https://www.gallerycdn.vsassets.io',
            },
            {
                category: 'settings',
                displayName: 'vscode-sync.trafficmanager.net',
                pingUrl: 'https://vscode-sync.trafficmanager.net',
            },
            {
                category: 'google-auth',
                displayName: 'accounts.google.com',
                pingUrl: 'https://accounts.google.com',
            },
            {
                category: 'google-auth',
                displayName: 'myaccount.google.com',
                pingUrl: 'https://myaccount.google.com',
            },
            {
                category: 'google-auth',
                displayName: 'oauth2.googleapis.com',
                pingUrl: 'https://oauth2.googleapis.com',
            },
            {
                category: 'google-auth',
                displayName: 'ogs.google.com',
                pingUrl: 'https://ogs.google.com',
            },
            {
                category: 'google-auth',
                displayName: 'securetoken.googleapis.com',
                pingUrl: 'https://securetoken.googleapis.com',
            },
            {
                category: 'required',
                displayName: 'vscodeedu.app',
                pingUrl: 'https://vscodeedu.app',
            },
        ];

        if (cleverAuth) {
            // https://support.clever.com/hc/s/articles/203256516?language=en_US
            value.push(
                {
                    category: 'clever-auth',
                    displayName: '*.clever.com',
                    pingUrl: 'https://www.clever.com/',
                },
                {
                    category: 'clever-auth',
                    displayName: '*.s3.amazonaws.com',
                    pingUrl: 'https://s3.amazonaws.com',
                },
                {
                    category: 'clever-auth',
                    displayName: '*.twilio.com',
                    pingUrl: 'https://www.twilio.com/en-us',
                }
            );
        }

        if (curriculumAuthoring) {
            value.push({
                category: 'authoring',
                displayName: 'github.com',
                pingUrl: 'https://www.github.com/',
            });
        }

        value.sort((a, b) => compareLocale(a.displayName, b.displayName));
        return value;
    }, [cleverAuth, curriculumAuthoring]);
};
