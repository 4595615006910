import {
    Checkbox,
    Dialog,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '../../data';
import { AuthType } from '../../data/vsce-identity-provider';
import { CleverLogo, GoogleLogo, MicrosoftLogo } from '../../icons/sign-in';
import { SignInImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackContext, TrackedButton, TrackedLink } from '../common';
import { CloseButton } from '../dialogs';

const useStyles = makeStyles({
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        rowGap: tokens.spacingVerticalM,
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalXL,
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.gap(tokens.spacingHorizontalS),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalMNudge),
        '&:focus-visible': {
            outline: `2px solid ${tokens.colorStrokeFocus2}`,
            outlineOffset: '2px',
            boxShadow: 'none', // remove inner focus ring.
        },
    },
});

// User sign in dialog properties.
export type SignInDialogProps = Readonly<
    Partial<DialogProps> & {
        onSignIn: (type: AuthType, rememberMe: boolean, signUp: boolean) => void;
    }
>;

// User sign in dialog.
export default (props: SignInDialogProps) => {
    const { onSignIn, open } = props;
    const styles = useStyles();
    const appStyles = useAppStyles();
    const { cleverAuth } = useConfig();
    const [rememberMe, setRememberMe] = useState(false);
    const [signUp, setSignUp] = useState(false);

    // Reset state on reopening the dialog.
    useEffect(() => {
        if (open === true) {
            setRememberMe(false);
            setSignUp(false);
        }
    }, [open]);

    return (
        <TrackContext.Provider value={{ container: 'sign-in-dialog' }}>
            <Dialog {...props}>
                <DialogSurface className={appStyles.dialogNarrow}>
                    <DialogBody>
                        <DialogTitle action={<CloseButton tabIndex={-1} />}>
                            {!signUp && (
                                <FormattedMessage description="Sign in dialog title." defaultMessage="Sign in" />
                            )}
                            {signUp && (
                                <FormattedMessage description="Sign in dialog title." defaultMessage="Sign up" />
                            )}
                        </DialogTitle>
                        <DialogContent className={appStyles.flexRowCentered}>
                            <SignInImage className={appStyles.dialogImage} />
                            <div className={mergeClasses(appStyles.dialogContent, appStyles.alignCenter)}>
                                {!signUp && (
                                    <FormattedMessage
                                        description="Sign in dialog text."
                                        defaultMessage="Sign in to access your projects and lessons, track your progress and save your work."
                                    />
                                )}
                                {signUp && (
                                    <FormattedMessage
                                        description="Sign in dialog text."
                                        defaultMessage="Join now to access curriculum, track your progress and save your work."
                                    />
                                )}
                                <div />
                                <div className={styles.buttonsContainer}>
                                    <DialogTrigger action="close">
                                        <TrackedButton
                                            trackAction="microsoft"
                                            className={styles.button}
                                            appearance="secondary"
                                            onClick={() => onSignIn('Microsoft', rememberMe, signUp)}
                                        >
                                            <MicrosoftLogo className={appStyles.sizeXL} />
                                            <Divider vertical className={appStyles.flexGrow0} />
                                            <span className={appStyles.flexGrow1}>
                                                {!signUp && (
                                                    <FormattedMessage
                                                        description="Button text to continue signing in with Microsoft account."
                                                        defaultMessage="Continue with Microsoft"
                                                    />
                                                )}
                                                {signUp && (
                                                    <FormattedMessage
                                                        description="Button text to continue signing up with Microsoft account."
                                                        defaultMessage="Sign up with Microsoft"
                                                    />
                                                )}
                                            </span>
                                        </TrackedButton>
                                    </DialogTrigger>
                                    <DialogTrigger action="close">
                                        <TrackedButton
                                            trackAction="google"
                                            className={styles.button}
                                            appearance="secondary"
                                            onClick={() => onSignIn('Google', rememberMe, signUp)}
                                        >
                                            <GoogleLogo className={appStyles.sizeXL} />
                                            <Divider vertical className={appStyles.flexGrow0} />
                                            <span className={appStyles.flexGrow1}>
                                                <span className={appStyles.flexGrow1}>
                                                    {!signUp && (
                                                        <FormattedMessage
                                                            description="Button text to continue signing in with Google account."
                                                            defaultMessage="Continue with Google"
                                                        />
                                                    )}
                                                    {signUp && (
                                                        <FormattedMessage
                                                            description="Button text to continue signing up with Google account."
                                                            defaultMessage="Sign up with Google"
                                                        />
                                                    )}
                                                </span>
                                            </span>
                                        </TrackedButton>
                                    </DialogTrigger>
                                    {cleverAuth && (
                                        <DialogTrigger action="close">
                                            <TrackedButton
                                                trackAction="clever"
                                                className={styles.button}
                                                appearance="secondary"
                                                onClick={() => onSignIn('Clever', rememberMe, signUp)}
                                            >
                                                <CleverLogo className={appStyles.sizeXL} />
                                                <Divider vertical className={appStyles.flexGrow0} />
                                                <span className={appStyles.flexGrow1}>
                                                    {!signUp && (
                                                        <FormattedMessage
                                                            description="Button text to continue signing in with Clever account."
                                                            defaultMessage="Continue with Clever"
                                                        />
                                                    )}
                                                    {signUp && (
                                                        <FormattedMessage
                                                            description="Button text to continue signing up with Clever account."
                                                            defaultMessage="Sign up with Clever"
                                                        />
                                                    )}
                                                </span>
                                            </TrackedButton>
                                        </DialogTrigger>
                                    )}
                                </div>
                                <Checkbox
                                    size="large"
                                    checked={rememberMe}
                                    onChange={(_, { checked }) => setRememberMe(checked === true)}
                                    label={
                                        <FormattedMessage
                                            description="Check box to remember user on Sign In dialog."
                                            defaultMessage="Remember me"
                                        />
                                    }
                                />
                                {signUp && (
                                    <>
                                        <div>
                                            <FormattedMessage
                                                description="Text on the Sign In dialog."
                                                defaultMessage="Already have an account?"
                                            />
                                            &nbsp;
                                            <TrackedLink trackAction="sign-in" onClick={() => setSignUp(false)}>
                                                <FormattedMessage
                                                    description="Sign in button text on the Sign In dialog."
                                                    defaultMessage="Sign in"
                                                />
                                            </TrackedLink>
                                        </div>
                                    </>
                                )}
                                {!signUp && (
                                    <>
                                        <div>
                                            <FormattedMessage
                                                description="Text on the Sign In dialog."
                                                defaultMessage="Don't have an account?"
                                            />
                                            &nbsp;
                                            <TrackedLink trackAction="sign-up" onClick={() => setSignUp(true)}>
                                                <FormattedMessage
                                                    description="Sign up button text on the Sign In dialog."
                                                    defaultMessage="Sign up"
                                                />
                                            </TrackedLink>
                                        </div>
                                    </>
                                )}
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
};
