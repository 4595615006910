import { shorthands, tokens } from '@fluentui/react-components';
import { defineStyles } from './define-styles';
import { MOBILE_AND_BELOW, TABLET_ONLY } from './responsive';

// Card styles.
export const cardStyles = defineStyles({
    // A list of cards.
    cardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: tokens.spacingVerticalL,
        columnGap: tokens.spacingHorizontalL,
    },
    // A single card control.
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        rowGap: '0rem',
        color: tokens.colorNeutralForeground2,
        backgroundColor: tokens.colorNeutralBackground2,
        boxShadow: tokens.shadow4,
        minHeight: '12.5rem',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke3),
        overflow: 'hidden',
    },
    // Card size to fit exactly 3 cards in the container's width.
    cardThreeColumns: {
        width: 'calc((100% - 2.5rem) / 3)',
        [TABLET_ONLY]: {
            width: 'calc((100% - 2rem) / 2)',
        },
        [MOBILE_AND_BELOW]: {
            width: '100%',
        },
    },
    // Container for the image at the top of the card.
    cardMedia: {
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
        paddingTop: tokens.spacingVerticalS,
        ...shorthands.borderRadius('1rem'),
    },
    // Image at the top of the card.
    cardMediaImage: {
        aspectRatio: '360 / 150',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    },
    // Container for card contents besides the top image.
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        rowGap: tokens.spacingVerticalS,
        ...shorthands.padding(tokens.spacingHorizontalXXL),
    },
    // Category text at the top of the card.
    cardCategory: {
        color: tokens.colorNeutralForeground2,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        textTransform: 'uppercase',
    },
    // Main title on the card.
    cardTitle: {
        color: tokens.colorBrandForeground2,
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightBase500,
        fontWeight: tokens.fontWeightSemibold,
    },
    // Hero card title.
    cardTitleHero: {
        color: tokens.colorBrandForeground2,
        fontSize: tokens.fontSizeHero700,
        lineHeight: tokens.lineHeightHero700,
        fontWeight: tokens.fontWeightSemibold,
    },
    // Description text on the card.
    cardDescription: {
        maxHeight: '2.5rem',
        overflowY: 'hidden',
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        marginTop: tokens.spacingVerticalL,
        marginBottom: tokens.spacingVerticalL,
        color: tokens.colorNeutralForeground2,
    },
    // Card footer.
    cardFooter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: tokens.spacingVerticalM,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorNeutralForeground2,
    },
    // Status pill at the bottom of the card.
    cardPill: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: tokens.colorNeutralForeground2,
        backgroundColor: tokens.colorNeutralBackground1Hover,
        ...shorthands.padding(tokens.spacingHorizontalXS, tokens.spacingHorizontalS),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke3),
    },
    // Bottom progress bar
    cardProgress: {
        height: tokens.spacingVerticalXS,
    },
});
