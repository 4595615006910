import React, { HTMLProps } from 'react';
import { FormattedMessage } from 'react-intl';

// Required time label properties.
export type RequiredTimeLabelProps = Readonly<
    HTMLProps<HTMLDivElement> & {
        minutes: number;
    }
>;

// Required time label.
export default (props: RequiredTimeLabelProps) => {
    const { minutes } = props;
    const hours = Math.round(minutes / 60);
    return (
        <div {...props}>
            {hours > 1 && (
                <FormattedMessage
                    description="Time interval text. {HOURS} is number of hours."
                    defaultMessage="{HOURS} hours"
                    values={{ HOURS: hours }}
                />
            )}
            {hours <= 1 && (
                <FormattedMessage
                    description="Time interval text. {MINUTES} is number of minutes."
                    defaultMessage="{MINUTES} minutes"
                    values={{ MINUTES: minutes }}
                />
            )}
        </div>
    );
};
