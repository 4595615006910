import { mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CreateImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackedButton } from '../common';

// Project list empty state properties.
export type EmptyStateProps = Readonly<{
    onCreate: () => void;
}>;

// Project list empty state.
export default (props: EmptyStateProps) => {
    const { onCreate } = props;
    const styles = useAppStyles();

    return (
        <div className={styles.emptyState}>
            <CreateImage className={styles.emptyStateImage} />
            <FormattedMessage
                description="Empty project list message"
                defaultMessage="It's lonely in here... create a project to get started."
            />
            <TrackedButton
                trackAction="new-project"
                className={mergeClasses(styles.button, styles.buttonPrimary)}
                onClick={onCreate}
            >
                <FormattedMessage
                    description="Empty project list new project button"
                    defaultMessage="Create new project"
                />
            </TrackedButton>
        </div>
    );
};
