import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CreateImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackedButton } from '../common';
import { mergeClasses } from '@fluentui/react-components';

// Course progress list empty state.
export default () => {
    const styles = useAppStyles();
    const navigate = useNavigate();

    return (
        <div className={styles.emptyState}>
            <CreateImage className={styles.emptyStateImage} />
            <FormattedMessage
                description="Empty course progress list message"
                defaultMessage="Get started on an activity by picking one from the catalog."
            />
            <TrackedButton
                trackAction="new-project"
                appearance="primary"
                className={mergeClasses(styles.button, styles.buttonPrimary)}
                onClick={() => navigate('/courses')}
            >
                <FormattedMessage description="Explore activities button title" defaultMessage="Explore activities" />
            </TrackedButton>
        </div>
    );
};
