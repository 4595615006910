import React from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../components/common';
import { Image, ImageProps } from '@fluentui/react-components';
import { getFetchUrl } from '../utilities';

// Feedback frustrated image.
export const FeedbackFrustratedImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/feedback-frustrated.png')}
        alt={props.intl.formatMessage({
            description: 'Description of a feedback icon.',
            defaultMessage: 'Image showing frustrated smiley face.',
        })}
    />
));

// Feedback sad image.
export const FeedbackSadImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/feedback-sad.png')}
        alt={props.intl.formatMessage({
            description: 'Description of a feedback icon.',
            defaultMessage: 'Image showing sad smiley face.',
        })}
    />
));

// Feedback ok image.
export const FeedbackOkImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/feedback-ok.png')}
        alt={props.intl.formatMessage({
            description: 'Description of a feedback icon.',
            defaultMessage: 'Image showing ok smiley face.',
        })}
    />
));

// Feedback happy image.
export const FeedbackHappyImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/feedback-happy.png')}
        alt={props.intl.formatMessage({
            description: 'Description of a feedback icon.',
            defaultMessage: 'Image showing happy smiley face.',
        })}
    />
));

// Feedback excited image.
export const FeedbackExcitedImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/feedback-excited.png')}
        alt={props.intl.formatMessage({
            description: 'Description of a feedback icon.',
            defaultMessage: 'Image showing excited smiley face.',
        })}
    />
));
