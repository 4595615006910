import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Link,
    makeStyles,
} from '@fluentui/react-components';
import { Link16Regular } from '@fluentui/react-icons';
import { Unit, VSCodeUrl } from '@microsoft/vscodeedu-api';
import React, { MouseEvent, useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MOBILE_AND_BELOW, useAppStyles } from '../../styles';
import { useAsyncRunner } from '../../utilities/async-runner';
import { IntlProps, TrackedLink } from '../common';
import LessonDetails from './lesson-details';

const useStyles = makeStyles({
    indented: {
        marginLeft: '2rem',
        [MOBILE_AND_BELOW]: {
            marginLeft: '0.5rem',
        },
    },
    header: {
        '& span[role="button"]': { display: 'none' },
        '&:hover span[role="button"]': { display: 'unset' },
    },
});

// Course unit section properties.
type UnitSectionProps = IntlProps<{
    unit: Unit;
    openCourseUrl: string;
}>;

// Course unit section.
export default injectIntl((props: UnitSectionProps) => {
    const { unit, openCourseUrl, intl } = props;
    const appStyles = useAppStyles();
    const styles = useStyles();
    const runner = useAsyncRunner();
    const [expanded, setExpanded] = useState(false);
    const lessons = unit.lessons;
    const visibleLessons = expanded ? lessons : lessons?.slice(0, 3);
    const onExpandClick = useCallback(() => setExpanded(true), []);
    const onCopyLinkClick = useCallback(
        async (e: MouseEvent, id: string) => {
            e.preventDefault();
            runner.run({
                task: async () => {
                    const link = new VSCodeUrl(openCourseUrl, { selectCourseNode: id }).toString();
                    await navigator.clipboard.writeText(link);
                },
                successMessage: intl.formatMessage({
                    description: 'Message after link has been copied to clipboard.',
                    defaultMessage: 'Successfully copied link to the clipboard.',
                }),
                errorMessage: intl.formatMessage({
                    description: 'Message about failure to copy link to the clipboard.',
                    defaultMessage: 'An error occurred while copying link to the clipboard.',
                }),
            });
        },
        [intl, openCourseUrl, runner]
    );

    return (
        <AccordionItem value={unit.id}>
            <AccordionHeader className={styles.header}>
                <div className={appStyles.flexRowSmallGap}>
                    <span>{unit.title}</span>
                    <TrackedLink
                        trackAction="copy-unit-link"
                        onClick={(e) => onCopyLinkClick(e, unit.id)}
                        as="span"
                        title={intl.formatMessage({
                            description: 'Table of contents unit open unit link title',
                            defaultMessage: 'Copy unit link',
                        })}
                    >
                        <Link16Regular />
                    </TrackedLink>
                </div>
            </AccordionHeader>
            <AccordionPanel className={styles.indented}>
                <Accordion collapsible multiple>
                    {visibleLessons?.map((lesson) => (
                        <AccordionItem key={lesson.id} value={lesson.id}>
                            <AccordionHeader className={styles.header}>
                                <div className={appStyles.flexRowSmallGap}>
                                    <span>{lesson.title}</span>
                                    <TrackedLink
                                        trackAction="copy-lesson-link"
                                        onClick={(e) => onCopyLinkClick(e, lesson.id)}
                                        as="span"
                                        title={intl.formatMessage({
                                            description: 'Table of contents unit lesson unit link title',
                                            defaultMessage: 'Copy lesson link',
                                        })}
                                    >
                                        <Link16Regular />
                                    </TrackedLink>
                                </div>
                            </AccordionHeader>
                            <AccordionPanel className={styles.indented}>
                                <LessonDetails lesson={lesson} />
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                    {!expanded && visibleLessons && lessons && visibleLessons.length < lessons.length && (
                        <Link onClick={onExpandClick} style={{ marginLeft: '1rem' }}>
                            <FormattedMessage
                                description="Table of contents unit show all lessons link"
                                defaultMessage="Show all {NUM} lessons"
                                values={{ NUM: lessons.length }}
                            />
                        </Link>
                    )}
                </Accordion>
            </AccordionPanel>
        </AccordionItem>
    );
});
